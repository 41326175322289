import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    useDelete,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useMany,
    useGo,
} from "@refinedev/core";

import type { SelectProps } from 'antd';

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    useEditableTable,
    useDrawerForm,
    BooleanField,
    CreateButton,
} from "@refinedev/antd";

import { 
    FormOutlined, SearchOutlined, 
    MoreOutlined, EditOutlined, 
    CloseCircleOutlined, CopyOutlined 
} from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Grid,
    Menu,
    Dropdown,
    Space,
} from "antd";

import dayjs from "dayjs";

import {
    IMiniappSdk,
    ISdk,
    ISdkFilterVariables,
    IIdentity,
    ILayout,
    IMiniapp,
} from "interfaces";

import { useMemo } from "react";

import { 
    CreateMiniappOfSDK, 
    EditMiniappOfSDK,
} from "components/miniappOfSDK";

export const AppstoreSDKList: React.FC<IResourceComponentsProps> = () => {
    const { 
        tableProps, 
        sorter, 
        searchFormProps, 
        filters,
        setId: setEditId, 
    } = useEditableTable<
        ISdk,
        HttpError,
        ISdk,
        ISdkFilterVariables
    >({
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, miniapp_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "miniapp_id",
                    operator: "eq",
                    value: miniapp_id,
                },
            );
            return filters;
        },
    });

    const layoutIds = tableProps?.dataSource?.map((item) => item.dashboard_layout_id) ?? [];
    const { data: layoutList, isLoading } = useMany<ILayout>({
        resource: "layouts",
        ids: layoutIds,
        queryOptions: {
            enabled: layoutIds.length > 0,
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const go = useGo();

    const { show, edit, clone, list } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const breakpoint = Grid.useBreakpoint();

    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("sdks", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="clone"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CopyOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    clone("sdks", id);
                }}
            >
                {t("buttons.clone")}
            </Menu.Item> 
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("sdks.titles.list")} breadcrumb={null} >
                    <Table
                        {...tableProps}
                        expandable={{
                            expandedRowRender: !breakpoint.xs
                                ? expandedRowRender
                                : undefined,
                        }}
                        rowKey="id"
                        onRow={(record) => ({
                            // eslint-disable-next-line
                            onClick: (event: any) => {
                                if (event.target.nodeName === "TD") {
                                    setEditId && setEditId(record.id);
                                }
                            },
                        })}
                    >              
                        <Table.Column
                            key="hostapp_name"
                            dataIndex="hostapp_name"
                            title={t("sdks.fields.hostapp_name")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />       
                        <Table.Column
                            key="package_name"
                            dataIndex="package_name"
                            title={t("sdks.fields.package_name")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />   
                        <Table.Column
                            key="dashboard_layout_id"
                            dataIndex="dashboard_layout_id"
                            title={"Layout"}
                            render={(value) => {
                                if (isLoading) {
                                    return <TextField value="Loading..." />;
                                }
                                //list("products")
                                return (
                                    <Button 
                                        type="link"
                                        onClick={() => list("layouts", value)}
                                    >
                                        {
                                            layoutList?.data.find((item) => item.id === value)
                                                ?.layout_name
                                        }
                                    </Button>
                                );
                            }}
                        />      

                        <Table.Column
                            key="version_ios"
                            dataIndex="version_ios"
                            title={t("sdks.fields.version_ios")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />        
                        <Table.Column
                            key="version_android"
                            dataIndex="version_android"
                            title={t("sdks.fields.version_android")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />  

                        <Table.Column
                            key="status"
                            dataIndex="status"
                            title={t("sdks.fields.status")}
                            align="center"
                            render={(value) => (
                                <BooleanField
                                    value={value === 1}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}
                                    valueLabelTrue="Active"
                                    valueLabelFalse="Inactive"
                                />
                            )}
                        />

                        <Table.Column
                            key="note"
                            dataIndex="note"
                            title={t("sdks.fields.note")}
                            render={(value) => <TextField value={value} />}
                        />                         
                        <Table.Column<ISdk>
                            dataIndex="miniapp_actions"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />  
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_name,
            value: item.id,
            icon: item.icon,
        }
    });

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label={t("search.keyword")} name="q">
                <Input
                    placeholder="Package Name, Hostname Name"
                    prefix={<SearchOutlined />}
                />
            </Form.Item>

            <Form.Item label="Miniapps" name="miniapp_id">
                <Select
                    {...miniappSelectProps}
                    allowClear
                    placeholder="Search Miniapps"
                    options={miniappOptions}
                    optionRender={(option) => { 
                        return (
                            <Space>
                                <span role="img" aria-label={option.data.label}>
                                    <Avatar size={20} src={option.data.icon} /> 
                                </span>
                                {option.data.label}
                            </Space>
                        )
                    }}
                />
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t("buttons.filter")}
                </Button>
            </Form.Item>
        </Form>
    );
};

const SdkMiniappsTable: React.FC<{ record: ISdk }> = ({ record }) => {
    const t = useTranslate();

    const { mutate: mutateDelete } = useDelete();
    const { list } = useNavigation();

    const { sorter, tableProps: postTableProps } = useTable<IMiniappSdk>({
        resource: "sdk-miniapps",
        pagination: {
            mode: "off",
        },
        initialSorter: [
            {
                field: "ordering",
                order: "asc",
            },
        ],
        permanentFilter: [
            {
                field: "sdk_id",
                operator: "eq",
                value: record.id,
            },
        ],
        syncWithLocation: false,
    });

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IMiniappSdk>({
        action: "create",
        resource: "sdk-miniapps",
        redirect: false,
    });
    
    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IMiniappSdk>({
        action: "edit",
        resource: "sdk-miniapps",
        redirect: false,
    });

    const moreMenu = (record: IMiniappSdk) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="edit"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => editShow(record.id)}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="remove"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "sdk-miniapps",
                        id: record.id,
                        mutationMode: "undoable",
                    });
                }}
            >
                {"Remove"}
            </Menu.Item>
        </Menu>
    );

    return (
        <List title={"MiniApps Of SDK"}  breadcrumb={null}  
            headerButtons={({ createButtonProps }) => (
                <>
                    <CreateButton 
                        hideText={true}
                        onClick={() => createShow()}
                        meta={{ sdk_id: record.id }}
                    />
                </>
            )}
        >
            <Table {...postTableProps} rowKey="id">
                <Table.Column
                    key="icon"
                    dataIndex="icon"
                    title={"Icon"}
                    render={(value) => <Avatar size={74} src={value} />}
                />      
                <Table.Column
                    key="miniapp_name"
                    dataIndex="miniapp_name"
                    title={"Miniapp Name"}
                    render={(value) => {
                        return (
                            <Button 
                                type="link"
                                target="_blank"
                                onClick={() => list("miniapps")}
                            >
                                {value}
                            </Button>
                        )
                    }}
                />              
                <Table.Column
                    key="version_name"
                    dataIndex="version_name"
                    title={"Version Name"}
                    render={(value) => {
                        return (
                            <Button 
                                type="link"
                                target="_blank"
                                onClick={() => list("miniapp-versions")}
                            >
                                {value}
                            </Button>
                        )
                    }}
                />   
                <Table.Column
                    key="ordering"
                    dataIndex="ordering"
                    title={"Ordering"} 
                    align="center"
                    sorter
                />
                <Table.Column<IMiniappSdk>
                    dataIndex="miniapp_actions"
                    title={t("table.actions")}
                    render={(_, record) => (
                        <Dropdown
                            overlay={moreMenu(record)}
                            trigger={["click"]}
                        >
                            <MoreOutlined
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>
            <CreateMiniappOfSDK
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                sdkId={record.id}
            />
            <EditMiniappOfSDK
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />
        </List>
    );
};

const expandedRowRender = (record: ISdk) => {
    return <SdkMiniappsTable record={record} />;
};