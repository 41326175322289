import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { 
    useForm, 
    Create,
    useSelect, 
} from "@refinedev/antd";

import { Select, Button, Form, Input, Space, InputNumber } from "antd";

interface IFormValue {
    package_name: string;
    hostapp_name: string;
    status: number;
    note: string;
}

import { ILayout, ISection } from "interfaces";

export const MiniDashboardSectionOfCreate: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps } = useForm<IFormValue>();

    const { selectProps: layoutSelectProps } = useSelect<ILayout>({
        resource: "layouts",
        optionLabel: "layout_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });
    const { selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form 
                {...formProps} 
                layout="vertical"
                initialValues={{
                    ordering: 1,
                }}
            >
                <Form.Item
                    name={"dashboard_layout_id"}
                    label="Layout Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...layoutSelectProps} />
                </Form.Item>     
                <Form.Item
                    name={"dashboard_section_id"}
                    label="Section Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...sectionSelectProps} />
                </Form.Item>
                <Form.Item
                    label={"Ordering"}
                    name="ordering"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>                      
                <Form.Item
                    name={"status"}
                    label="Status"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Select
                        options={[
                            {
                                label: "Active",
                                value: 1,
                            },
                            {
                                label: "Inactive",
                                value: 2,
                            },
                        ]}
                    /> 
                </Form.Item>
                 
            </Form>
        </Create>
    );
}
