import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { 
    useForm, 
    useSelect, 
    Edit,
    getValueFromEvent,
} from "@refinedev/antd";

import { 
    Select, 
    Button, 
    Form, 
    Input, 
    Space, 
    Row,
    Col,
    Upload,
    Avatar,
    Typography,
    InputNumber, 
} from "antd";

const { Text } = Typography;

interface IFormValue {
    layout_name: string;
    status: number;
    note: string;
}

import { IMiniapp, ISection } from "interfaces";

export const MiniDashboardMiniAppOfEdit: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps } = useForm<IFormValue>();

    const { selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Edit title="Edit Miniapp Of Section" saveButtonProps={saveButtonProps}>
            <Form
                    {...formProps}
                    layout="vertical"
                >
                <Row gutter={20}>
                    <Col xs={24} lg={8}>
                        <Form.Item>
                            <Form.Item
                                name="banner"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                                noStyle
                            >
                                <Upload.Dragger
                                    name="file"
                                    action={`${process.env.REACT_APP_UPLOAD_API_URL}/upload/icon`}
                                    listType="picture"
                                    maxCount={1}
                                    multiple
                                    style={{
                                        border: "none",
                                        width: "100%",
                                        background: "none",
                                    }}
                                >
                                    <Space direction="vertical" size={2}>
                                        <Avatar
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                maxWidth: "200px",
                                            }}
                                            src="/images/product-default-img.png"
                                            alt="Banner Location"
                                        />
                                        <Text
                                            style={{
                                                fontWeight: 800,
                                                fontSize: "16px",
                                                marginTop: "8px",
                                            }}
                                        >
                                            {"Banner Image"}
                                        </Text>
                                        <Text style={{ fontSize: "12px" }}>
                                            
                                        </Text>
                                    </Space>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={16}>
                        <Row gutter={10}>
                            <Col xs={24} lg={12}>
                                 
                                <Form.Item
                                    name={"dashboard_section_id"}
                                    label="Section Name"
                                    style={{ maxWidth: "893px" }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select {...sectionSelectProps} />
                                </Form.Item>     

                                <Form.Item
                                    name={"link_url"}
                                    label="Link Url"
                                    style={{ maxWidth: "893px" }}
                                    rules={[{ type: 'string', min: 6 }, { type: 'url', whitespace: true}]}
                                    hasFeedback
                                >
                                    <Input placeholder="(http:// | https://)" />
                                </Form.Item>      

                                <Form.Item
                                    name={"miniapp_id"}
                                    label="MiniApp Name"
                                    style={{ maxWidth: "893px" }}
                                >
                                    <Select
                                        allowClear
                                        {...miniappSelectProps} 
                                    />
                                </Form.Item>       
                                <Form.Item
                                    label={"Ordering"}
                                    name="ordering"
                                    style={{ maxWidth: "893px" }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <InputNumber />
                                </Form.Item>                      
                                <Form.Item
                                    name={"status"}
                                    label="Status"
                                    style={{ maxWidth: "893px" }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Select
                                        options={[
                                            {
                                                label: "Active",
                                                value: 1,
                                            },
                                            {
                                                label: "Inactive",
                                                value: 2,
                                            },
                                        ]}
                                    /> 
                                </Form.Item>
                                     
                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
}
