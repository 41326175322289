import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useDelete,
    useMany
} from "@refinedev/core";

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    BooleanField,
    useSelect,
} from "@refinedev/antd";

import { FormOutlined, SearchOutlined, MoreOutlined, EditOutlined, CloseCircleOutlined } from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Grid,
    Menu,
    Dropdown,
    Space,
} from "antd";

import dayjs from "dayjs";

import {
    ISection,
    ILayout,
    ISectionType,
    IIdentity,
    ILayoutSection,
    ISectionFilterVariables,
} from "interfaces";

import { useMemo } from "react";

export const MiniDashboardSectionOfList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, searchFormProps, filters } = useTable<
        ISection,
        HttpError,
        ISectionFilterVariables
    >({
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, layout_id, section_id, type } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "layout_id",
                    operator: "eq",
                    value: layout_id,
                },
                {
                    field: "section_id",
                    operator: "eq",
                    value: section_id,
                },
                {
                    field: "type",
                    operator: "eq",
                    value: type,
                }, 
            );
            return filters;
        },
    });

    const { data: typeList, isLoading } = useMany<ISectionType>({
        resource: "section-types",
        ids: [],
        queryOptions: {
            enabled: true,
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit, list } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("layout-sections", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="reject"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "layout-sections",
                        id,
                        mutationMode: "undoable",
                    });
                }}
            >
                {t("buttons.delete")}
            </Menu.Item>
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card title="Filters">
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("sections.titles.list")} breadcrumb={null} >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    // show("miniapp-versions", record.id);
                                },
                            };
                        }}
                    >
                        <Table.Column
                            key="layout_name"
                            dataIndex="layout_name"
                            title={"Layout Name"}
                            render={(value) => {
                                return (
                                    <Button 
                                        type="link"
                                        target="_blank"
                                        onClick={() => list("layouts")}
                                    >
                                        {value}
                                    </Button>
                                )
                            }} 
                            sorter={{ multiple: 1 }}
                        /> 
                        <Table.Column
                            key="section_name"
                            dataIndex="section_name"
                            title={"Section Name"}
                            render={(value) => {
                                return (
                                    <Button 
                                        type="link"
                                        target="_blank"
                                        onClick={() => list("sections")}
                                    >
                                        {value}
                                    </Button>
                                )
                            }}
                        />                 
                        <Table.Column<ISectionType>
                            key="type" 
                            dataIndex="type"
                            title={"Type"} 
                            render={(value) => {
                                if (isLoading) {
                                    return <TextField value="Loading..." />;
                                }
                                //list("products")
                                return (
                                    <Button 
                                        type="link"
                                        onClick={() => list("section-types", value)}
                                    >
                                        {
                                            typeList?.data.find((item) => item.id === value)
                                                ?.name
                                        }
                                    </Button>
                                );
                            }}
                        />  
                        <Table.Column
                            key="ordering"
                            dataIndex="ordering"
                            title={"Ordering"}
                            align="center"
                            render={(value) => <TextField value={value} />}
                            sorter={{ multiple: 1 }}
                        />   
                        <Table.Column
                            key="status"
                            dataIndex="status"
                            title={"Status"}
                            align="center"
                            render={(value) => (
                                <BooleanField
                                    value={value === 1}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}
                                    valueLabelTrue="Active"
                                    valueLabelFalse="Inactive"
                                />
                            )}
                            sorter
                        />                              
                        <Table.Column
                            key="created_at"
                            dataIndex="created_at"
                            title={"Created At"}
                            render={(value) => (
                                <DateField value={value} format={t("format.date")} />
                            )}
                            sorter
                        />
                        <Table.Column<ILayoutSection>
                            dataIndex="actions"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        /> 
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const { selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { selectProps: layoutSelectProps } = useSelect<ILayout>({
        resource: "layouts",
        optionLabel: "layout_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Form layout="inline" {...formProps}>
            <Space size={[8, 8]} wrap>
                <Form.Item label="Search" name="q">
                    <Input
                        placeholder="Layout Name, Section Name"
                        prefix={<SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item label="Type" name="type">
                    <Select
                        allowClear
                        options={[
                            {
                                label: "List",
                                value: 1,
                            },
                            {
                                label: "Grid",
                                value: 2,
                            },
                            {
                                label: "Banner",
                                value: 3,
                            },
                        ]}
                        placeholder="Type"
                    />
                </Form.Item>
                <Form.Item label="Sections" name="section_id">
                    <Select
                        {...sectionSelectProps}
                        allowClear
                        placeholder="Search Sections"
                    />
                </Form.Item>
                <Form.Item label="Layouts" name="layout_id">
                    <Select
                        {...layoutSelectProps}
                        allowClear
                        placeholder="Search Layouts"
                    />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        Filter
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
};