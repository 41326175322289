import React from "react";
import { 
    Authenticated,
    CanAccess, 
    Refine,
    Action,
    IResourceItem,
    useParsed,
    useTranslate 
} from "@refinedev/core";

import { RefineKbarProvider } from "@refinedev/kbar";
import {
    notificationProvider,
    ThemedLayoutV2,
    ThemedSiderV2,
    ErrorComponent,
} from "@refinedev/antd";
import routerProvider, {
    CatchAllNavigate,
    NavigateToResource,
    UnsavedChangesNotifier,
    DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import {
    ShoppingOutlined,
    UsergroupAddOutlined,
    ShopOutlined,
    StarOutlined,
    DashboardOutlined,
    GlobalOutlined,
    AppstoreOutlined,
    ApartmentOutlined,
    LayoutOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import jsonServerDataProvider from "@refinedev/simple-rest";
import { authProvider } from "authProvider";

import "dayjs/locale/en";

import { AuthPage } from "./pages/auth";

import { 
    MiniDashboardSectionOfList, 
    MiniDashboardSectionOfCreate, 
    MiniDashboardSectionOfEdit, 
} from "./pages/mini-dashboard/section-of";

import { 
    MiniDashboardMiniAppOfList, 
    MiniDashboardMiniAppOfCreate, 
    MiniDashboardMiniAppOfEdit, 
} from "./pages/mini-dashboard/miniapp-of";

import { 
    MiniDashboardLayoutList, 
    MiniDashboardLayoutCreate, 
    MiniDashboardLayoutEdit, 
} from "./pages/mini-dashboard/layout";

import { 
    MiniDashboardSectionList, 
    MiniDashboardSectionCreate, 
    MiniDashboardSectionEdit, 
} from "./pages/mini-dashboard/section";

import { 
    AppstoreMiniAppOfList, 
    AppstoreMiniAppOfCreate,
    AppstoreMiniAppOfEdit,
} from "./pages/appstore/miniapp-of";

import { 
    AppstoreMiniAppVersionList, 
    AppstoreMiniAppVersionCreate,
    AppstoreMiniAppVersionEdit,
    AppstoreMiniAppVersionPermission,
} from "./pages/appstore/miniapp-version";

import { 
    AppstoreSDKList, 
    AppstoreSDKCreate,
    AppstoreSDKEdit,
} from "./pages/appstore/sdk";

import { 
    AppstoreTranslateList,
    AppstoreTranslateEdit,
} from "./pages/appstore/translates";

import { 
    AppstoreUserList, 
    AppstoreUserCreate,
    AppstoreUserEdit,
} from "./pages/appstore/users";

import { 
    AppstoreMiniappList, 
    AppstoreMiniappCreate, 
    AppstoreMiniappEdit, 
    AppstoreMiniappShow, 
} from "./pages/appstore/miniapp";

import { 
    AppstorePermissionList,  
} from "./pages/appstore/permission";

import { 
    ChangePasswordPage,
} from "./pages/accounts";

import { useTranslation } from "react-i18next";
import { Header, Title } from "components";
import { ConfigProvider } from "context";
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from "./constants";

import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const API_URL = process.env.REACT_APP_API_URL;

// =========== begin axios config ======
const axiosInstance = axios.create();

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) => 
    axiosInstance
        .post(`${API_URL}/token-refresh`, {refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY)})
        .then((tokenRefreshResponse) => {
            localStorage.setItem(TOKEN_KEY, tokenRefreshResponse.data.token);

            failedRequest.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.token;

            return Promise.resolve();
        });

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

// https://refine.dev/docs/tutorial/understanding-authprovider/create-authprovider/
// axios-auth-refresh
axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (request.headers) {
        request.headers["Authorization"] = `Bearer ${token}`;
    } else {
        request.headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    return request;
});
// =========== end axios config ======

import "@refinedev/antd/dist/reset.css";
import "./index.css";

const App: React.FC = () => {

    const AUDIT_LOG_API_URL = process.env.REACT_APP_AUDIT_LOG_API_URL;

    const dataProvider = jsonServerDataProvider(API_URL, axiosInstance);

    /// === APPSTORE API ==
    const APPSTORE_API_URL = process.env.REACT_APP_APPSTORE_API_URL;
    const appstoreProvider = jsonServerDataProvider(APPSTORE_API_URL, axiosInstance);

    /// === DASHBOARD API ==
    const DASHBOARD_API_URL = process.env.REACT_APP_DASHBOARD_API_URL;
    const dashboardProvider = jsonServerDataProvider(DASHBOARD_API_URL, axiosInstance);

    const { t, i18n } = useTranslation();

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const customTitleHandler = (options:{
        resource?: IResourceItem,
        action?: Action,
        params?: Record<string, string | undefined>,
        pathname?: string,
        autoGeneratedTitle: string,
    }) => {

        let title = "MiniApps"; // Default title
        const { action, params, pathname, resource, autoGeneratedTitle } = options;
        if (resource && action) {
            title = `MiniDashboard | ${resource.label}`;
        }
        return title;
    };

    return (
        <BrowserRouter>
            <ConfigProvider>
                <RefineKbarProvider>
                    <Refine
                        auditLogProvider={{
                            get: async ({ resource, meta }) => {
                                const { data } = await jsonServerDataProvider(AUDIT_LOG_API_URL).getList({
                                    resource: "audit-logs",
                                    filters: [
                                        {
                                            field: "resource",
                                            operator: "eq",
                                            value: resource,
                                        },
                                        {
                                            field: "meta.id",
                                            operator: "eq",
                                            value: meta?.id,
                                        },
                                    ],
                                });

                                return data;
                            },
                            create: (params) => {
                                return jsonServerDataProvider(AUDIT_LOG_API_URL).create({
                                    resource: "audit-logs",
                                    variables: params,
                                });
                            },
                            update: async ({ id, name }) => {
                                const { data } = await jsonServerDataProvider(AUDIT_LOG_API_URL).update({
                                    resource: "audit-logs",
                                    id,
                                    variables: { name },
                                });
                                return data;
                            },
                        }}
                        routerProvider={routerProvider}
                        dataProvider={{
                            default: appstoreProvider,
                            appstore: appstoreProvider,
                            dashboard: dashboardProvider,
                        }}
                        authProvider={authProvider(APPSTORE_API_URL)}
                        i18nProvider={i18nProvider}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                        notificationProvider={notificationProvider}
                        resources={[
                            {
                                name: "sdk-setting",
                                meta: {
                                    label: t("menus.sdkSetting"),
                                    icon: <AppstoreOutlined />,
                                },
                            },
                            {
                                name: "sdks",
                                list: "/sdks",
                                create: "/sdks/create",
                                edit: "/sdks/edit/:id",
                                clone: "/sdks/clone/:id",
                                meta: {
                                    label: t("menus.SDKs"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                    canDelete: false,
                                },
                            },
                            {
                                name: "sdk-miniapps",
                                list: "/sdk-miniapps",
                                create: "/sdk-miniapps/create",
                                edit: "/sdk-miniapps/edit/:id",
                                meta: {
                                    label: t("menus.miniappOfSdk"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                },
                            },
                            {
                                name: "miniapp-versions",
                                list: "/miniapp-versions",
                                create: "/miniapp-versions/create",
                                edit: "/miniapp-versions/edit/:id",
                                clone: "/miniapp-versions/clone/:id",
                                meta: {
                                    label: t("menus.miniappVersions"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                },
                            },
                            {
                                name: "miniapps",
                                list: "/miniapps",
                                create: "/miniapps/create",
                                edit: "/miniapps/edit/:id",
                                show: "/miniapps/show/:id",
                                meta: {
                                    label: t("menus.miniapps"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore",
                                },
                            },                      
                            {
                                name: "permissions",
                                list: "/permissions", 
                                meta: {
                                    label: t("menus.permissions"),
                                    parent: "sdk-setting",
                                    dataProviderName: "appstore", 
                                },
                            },                        
                            {
                                name: "miniapp-permissions",
                                edit: "/miniapp-versions/:id/permissions", 
                                meta: {
                                    dataProviderName: "appstore",
                                    hide: true,
                                },
                            },
                            {
                                name: "mini-dashboard",
                                meta: {
                                    label: 'Mini Dashboard',
                                    icon: <LayoutOutlined />,
                                },
                            },
                            {
                                name: "layouts",
                                list: "/",
                                create: "/layouts/create",
                                edit: "/layouts/edit/:id",
                                clone: "/layouts/clone/:id",
                                meta: {
                                    label: t("menus.layouts"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                },
                            },
                            {
                                name: "layout-sections",
                                list: "/layout-sections",
                                create: "/layout-sections/create",
                                edit: "/layout-sections/edit/:id",
                                meta: {
                                    label: 'Sections Of',
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                    hide: true,
                                },
                            },
                            {
                                name: "sections",
                                list: "/sections",
                                create: "/sections/create",
                                edit: "/sections/edit/:id",
                                clone: "/sections/clone/:id",
                                meta: {
                                    label: t("menus.sections"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                },
                            },
                            {
                                name: "section-types",
                                meta: {
                                    hide: true,
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                },
                            },
                            {
                                name: "section-miniapps",
                                list: "/section-miniapps",
                                create: "/section-miniapps/create",
                                clone: "/section-miniapps/create/:section_id",
                                edit: "/section-miniapps/edit/:id",
                                meta: {
                                    label: t("menus.miniappOfSection"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                    canDelete: false,
                                    hide: true,
                                },
                            },
                            {
                                name: "vouchers",
                                list: "/vouchers", 
                                meta: {
                                    label: t("menus.vouchers"),
                                    parent: "mini-dashboard",
                                    dataProviderName: "dashboard",
                                    canDelete: false,
                                    hide: true,
                                },
                            }, 
                            {
                                name: "translates",
                                list: "/translates",
                                edit: "/translates/edit/:id",
                                meta: {
                                    label: t("menus.translates"),
                                    icon: <GlobalOutlined />,
                                    dataProviderName: "appstore",
                                },
                            },       

                            {
                                name: "users",
                                list: "/users",
                                create: "/users/create",
                                edit: "/users/edit/:id",
                                meta: {
                                    label: t("menus.developers"),
                                    icon: <UsergroupAddOutlined />,
                                },
                            },
                            // This will add an item to `<Sider/>` with route `/home-page`
                            { 
                                name: "account",
                                meta: {
                                    label: t("menus.accountSetting"),
                                    icon: <SettingOutlined />,
                                }, 
                            },
                            { 
                                name: "change-password", 
                                list: "/account/change-password", 
                                meta: {
                                    parent: "account",
                                    label: t("menus.changePassword"),
                                },
                            },
                        ]}
                    >
                        <Routes>
                            <Route
                                element={
                                    <Authenticated
                                        fallback={
                                            <CatchAllNavigate to="/login" />
                                        }
                                    >
                                        <ThemedLayoutV2 
                                            Header={Header}
                                            Title={Title}
                                        >
                                            <CanAccess
                                                fallback={<div>Unauthorized!</div>}
                                            >
                                                <Outlet />
                                            </CanAccess>
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route index element={<MiniDashboardLayoutList />} />

                                <Route path="/sdks">
                                    <Route index element={<AppstoreSDKList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreSDKCreate />}
                                    />                                    
                                    <Route
                                        path="clone/:id"
                                        element={<AppstoreSDKCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreSDKEdit />}
                                    />
                                </Route> 

                                <Route path="/sdk-miniapps">
                                    <Route index element={<AppstoreMiniAppOfList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreMiniAppOfCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreMiniAppOfEdit />}
                                    />
                                </Route> 

                                {/* Account Setting */}
                                <Route path="/account">
                                    <Route
                                        path="change-password"
                                        element={<ChangePasswordPage />}
                                    />
                                </Route> 
                                

                                <Route path="/miniapp-versions">
                                    <Route index element={<AppstoreMiniAppVersionList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreMiniAppVersionCreate />}
                                    />
                                    <Route
                                        path="clone/:id"
                                        element={<AppstoreMiniAppVersionCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreMiniAppVersionEdit />}
                                    />
                                    <Route
                                        path=":id/permissions"
                                        element={<AppstoreMiniAppVersionPermission />}
                                    />
                                </Route>

                                <Route path="/miniapps">
                                    <Route index element={<AppstoreMiniappList />} />
                                    <Route
                                        path="create"
                                        element={<AppstoreMiniappCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreMiniappEdit />}
                                    />
                                    <Route
                                        path="show/:id"
                                        element={<AppstoreMiniappShow />}
                                    />
                                </Route> 

                                <Route path="/permissions">
                                    <Route index element={<AppstorePermissionList />} /> 
                                </Route> 

                                <Route path="/layouts">
                                    <Route index element={<MiniDashboardLayoutList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardLayoutCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardLayoutEdit />}
                                    />
                                    <Route path="clone/:id" element={<MiniDashboardLayoutCreate />} />
                                </Route> 

                                <Route path="/sections">
                                    <Route index element={<MiniDashboardSectionList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardSectionCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardSectionEdit />}
                                    />
                                    <Route
                                        path="clone/:id"
                                        element={<MiniDashboardSectionCreate />}
                                    />
                                </Route> 

                                <Route path="/layout-sections">
                                    <Route index element={<MiniDashboardSectionOfList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardSectionOfCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardSectionOfEdit />}
                                    />
                                </Route> 

                                <Route path="/section-miniapps">
                                    <Route index element={<MiniDashboardMiniAppOfList />} />
                                    <Route
                                        path="create"
                                        element={<MiniDashboardMiniAppOfCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MiniDashboardMiniAppOfEdit />}
                                    />
                                </Route> 

                                <Route path="/translates">
                                    <Route index element={<AppstoreTranslateList />} />
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreTranslateEdit />}
                                    />
                                </Route> 

                                <Route path="/users">
                                    <Route index element={<AppstoreUserList />} />
                                    <Route
                                        path="create"
                                        element={
                                            <CanAccess fallback={<div>Unauthorized!</div>}>
                                                <AppstoreUserCreate />
                                            </CanAccess>
                                        }
                                    />                       
                                    <Route
                                        path="edit/:id"
                                        element={<AppstoreUserEdit />}
                                    />
                                </Route> 

                                <Route
                                    path="/update-password"
                                    element={<AuthPage type="updatePassword" />}
                                />

                            </Route>

                            <Route
                                element={
                                    <Authenticated fallback={<Outlet />}>
                                        <NavigateToResource resource="sdks" />
                                    </Authenticated>
                                }
                            >
                                <Route
                                    path="/login"
                                    element={
                                        <AuthPage
                                            type="login"
                                            formProps={{
                                                initialValues: {
                                                    // email: "minidashboard@taxidayroi.vn",
                                                    // password: "abc123",
                                                },
                                            }}
                                        />
                                    }
                                />
                            </Route>

                            <Route
                                element={
                                    <Authenticated>
                                        <ThemedLayoutV2
                                            Header={Header}
                                            Title={Title}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route path="*" element={<ErrorComponent />} />
                            </Route>
                        </Routes>
                        <UnsavedChangesNotifier />
                        <DocumentTitleHandler handler={customTitleHandler} />
                    </Refine>
                </RefineKbarProvider>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
