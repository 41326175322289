import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useDelete,
} from "@refinedev/core";

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    BooleanField,
} from "@refinedev/antd";

import { CloudUploadOutlined, FormOutlined, SearchOutlined, MoreOutlined, EditOutlined, CloseCircleOutlined, GlobalOutlined } from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
    QRCode,
} from "antd";

import dayjs from "dayjs";

import {
    IMiniapp,
    IMiniappFilterVariables,
    IIdentity,
} from "interfaces";
import { useMemo } from "react";

export const AppstoreMiniappList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, searchFormProps, filters } = useTable<
        IMiniapp,
        HttpError,
        IMiniappFilterVariables
    >({
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, app_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "app_id",
                    operator: "eq",
                    value: app_id,
                },
            );
            return filters;
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const moreMenu = (id: number, record: any) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("miniapps", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="translate"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <GlobalOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    edit("translates", `miniapp${record.text_uuid}`);
                }}
            >
                {"Translate"}
            </Menu.Item>
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("miniapps.titles.list")} breadcrumb={null} >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    // show("miniapp-versions", record.id);
                                },
                            };
                        }}
                        scroll={{ x: 'calc(500px + 70%)', y: 'calc(100vh - 385px)' }}
                    >           
                        <Table.Column
                            key="icon"
                            dataIndex="icon"
                            title={"Icon"}
                            width="100px"
                            render={(value) => {
                                return (
                                    <Popover 
                                    overlayInnerStyle={{ padding: 0 }} 
                                    content={
                                        <QRCode
                                            size={250}
                                            iconSize={40}
                                            errorLevel="H" 
                                            value={`supperapp://miniapp/dl/929e2b5c-156b-11ee-84df-02e37651594e`}
                                            icon={value}
                                            bordered={true}
                                        />
                                    }>
                                        <Avatar size={74} src={value} />
                                    </Popover>
                                )
                            }}
                        />  
                        <Table.Column
                            key="package_name"
                            dataIndex="package_name"
                            title={t("miniapps.fields.package_name")} 
                            width="200px"
                            render={(value) => {
                                return {
                                    children: <TextField value={value} />,
                                    props: {
                                        style: {
                                            minWidth: "150px",
                                        },
                                    },
                                }
                            }}
                            sorter
                        />             
                        <Table.Column<IMiniapp>
                            key="miniapp_name"
                            dataIndex="miniapp_name"
                            title={t("miniapps.fields.miniapp_name")}
                            width="200px"
                            render={(value, record) => {
                                return {
                                    children: (
                                        <Button 
                                            type="link"
                                            target="_blank"
                                            onClick={() => edit("miniapps", record.id)}
                                        >
                                            {value}
                                        </Button>
                                    ),
                                    props: {
                                        style: {
                                            maxWidth: "150px",
                                        },
                                    },
                                }
                            }}
                            sorter
                        />   
                        <Table.Column
                            key="status"
                            dataIndex="status"
                            title={t("miniapps.fields.status")}
                            width="120px"
                            align="center"
                            render={(value) => (
                                <BooleanField
                                    value={value === 1}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}
                                    valueLabelTrue="Active"
                                    valueLabelFalse="Inactive"
                                />
                            )}
                        />    
                        <Table.Column
                            key="note"
                            dataIndex="note"
                            title={t("miniapps.fields.note")}
                            render={(value) => {
                                return {
                                    children: <TextField value={value} />,
                                    props: {
                                        style: {
                                            maxWidth: "1400px",
                                        },
                                    },
                                }
                            }}
                        />
                        <Table.Column
                            key="app_id"
                            dataIndex="app_id" 
                            title={t("miniapps.fields.app_id")}
                            width="200px"
                            render={(value) => {
                                return {
                                    children: <TextField value={value} />,
                                    props: {
                                        style: {
                                            maxWidth: "120px",
                                        },
                                    },
                                }
                            }}
                        />        
                        <Table.Column<IMiniapp>
                            dataIndex="miniapp_actions"
                            fixed="right"
                            width="90px"
                            align="center"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id, record)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />  
                    </Table>
                </List>
            </Col>
        </Row>
    );
};


const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label={t("search.keyword")} name="q">
                <Input
                    placeholder="Package Name, Miniapp Name"
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="App ID" name="app_id">
                <Input
                    placeholder="App ID"
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t("buttons.filter")}
                </Button>
            </Form.Item>
        </Form>
    );
};