import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { useForm, useSelect, Edit } from "@refinedev/antd";

import { Select, Button, Form, Input, Space, InputNumber, Radio } from "antd";

interface IFormValue {
    layout_name: string;
    status: number;
    note: string;
}

import { ILayout, ISection } from "interfaces";

export const MiniDashboardSectionOfEdit: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps } = useForm<IFormValue>();

    const { selectProps: layoutSelectProps } = useSelect<ILayout>({
        resource: "layouts",
        optionLabel: "layout_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });
    const { selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Edit title="Edit Section" saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                 <Form.Item
                    name={"dashboard_layout_id"}
                    label="Layout Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...layoutSelectProps} />
                </Form.Item>     
                <Form.Item
                    name={"dashboard_section_id"}
                    label="Section Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...sectionSelectProps} />
                </Form.Item>
                <Form.Item
                    label={"Ordering"}
                    name="ordering"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>                      
                <Form.Item
                    name={"status"}
                    label="Status"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Radio.Group>
                        <Radio value={1}>
                            {"Active"}
                        </Radio>
                        <Radio value={2}>
                            {"Inactive"}
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                 
            </Form>
        </Edit>
    );
}
