import { useMemo } from "react";
import dayjs from "dayjs";

import {
    useMany,
    useGetIdentity,
    useTranslate,
    useDelete,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import {
    List,
    TextField,
    BooleanField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    useEditableTable,
    useDrawerForm,
    CreateButton,
    CloneButton,
} from "@refinedev/antd";

import { 
    FormOutlined, 
    SearchOutlined, 
    MoreOutlined, 
    EditOutlined, 
    CloseCircleOutlined, 
    CopyOutlined,
    GlobalOutlined, 
} from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Grid,
    Menu,
    Dropdown,
    Image,
    Typography,
    Space,
} from "antd";

const { Text, Link } = Typography;
const { Meta } = Card;

import {
    IMiniapp,
    ISection,
    IIdentity,
    ISectionType,
    IMiniappSection,
    ILayout,
    ISectionFilterVariables
} from "interfaces";

import { 
    SectionVouchersTable,
    SectionBannersTable,
    SectionMiniappsTable,
} from "components/section";

import { 
    EditMiniappOfSection,
    CreateMiniappOfSection, 
} from "components/miniappOfSection";

export const MiniDashboardSectionList: React.FC<IResourceComponentsProps> = () => {
    
    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit, list, clone } = useNavigation();

    const { mutate: mutateDelete } = useDelete();

    const { 
        tableProps, 
        sorter, 
        searchFormProps, 
        filters,
        setId: setEditId, 
    } = useEditableTable<
        ISection,
        HttpError,
        ISection,
        ISectionFilterVariables
    >({
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, type, miniapp_id, layout_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "type",
                    operator: "eq",
                    value: type,
                }, 
                {
                    field: "miniapp_id",
                    operator: "eq",
                    value: miniapp_id,
                }, 
                {
                    field: "layout_id",
                    operator: "eq",
                    value: layout_id,
                }, 
            );
            return filters;
        },
    });

    const { data: typeList, isLoading } = useMany<ISectionType>({
        resource: "section-types",
        ids: [],
        queryOptions: {
            enabled: true,
        },
    });

    const breakpoint = Grid.useBreakpoint();

    const moreMenu = (id: number, record: any) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("sections", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="clone"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CopyOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    clone("sections", id);
                }}
            >
                {t("buttons.clone")}
            </Menu.Item> 
            <Menu.Item
                key="translate"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <GlobalOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    edit("translates", `section${record.text_uuid}`);
                }}
            >
                {"Translate"}
            </Menu.Item>
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("sections.titles.list")}  breadcrumb={null} >
                    <Table
                        {...tableProps}
                        expandable={{
                            expandedRowRender: !breakpoint.xs
                                ? expandedRowRender
                                : undefined,
                        }}
                        rowKey="id"
                        onRow={(record) => ({
                            // eslint-disable-next-line
                            onClick: (event: any) => {
                                if (event.target.nodeName === "TD") {
                                    setEditId && setEditId(record.id);
                                }
                            },
                        })}
                    >
                        <Table.Column
                            key="section_name"
                            dataIndex="section_name"
                            title={t("sections.fields.section_name")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />            
                        <Table.Column<ISection>
                            key="layouts"
                            dataIndex="layouts"
                            title={'Layouts'}
                            render={(_, record) => {

                                return (
                                    <Popover
                                        content={
                                            <ul>
                                                {record.layouts?.map((layout) => (
                                                    <li key={layout.id}>
                                                        <Button 
                                                            type="link"
                                                            target="_blank"
                                                            onClick={() => list("layouts")}
                                                        >
                                                            {layout.layout_name}
                                                        </Button>                       
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                        title="Layouts"
                                        trigger="hover"
                                    >
                                        {t("orders.fields.itemsAmount", {
                                            amount: record.layouts?.length,
                                        })}
                                    </Popover>
                                )
                            }}
                        />          
                        <Table.Column
                            key="status"
                            dataIndex="status"
                            title={t("sections.fields.status")}
                            align="center"
                            render={(value) => (
                                <BooleanField
                                    value={value === 1}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}
                                    valueLabelTrue="Active"
                                    valueLabelFalse="Inactive"
                                />
                            )}
                        />     
                        <Table.Column<ISectionType>
                            key="type" 
                            dataIndex="type"
                            title={t("sections.fields.type")} 
                            render={(value) => {
                                if (isLoading) {
                                    return <TextField value="Loading..." />;
                                }
                                //list("products")
                                return (
                                    <Button 
                                        type="link"
                                        onClick={() => list("section-types", value)}
                                    >
                                        {
                                            typeList?.data.find((item) => item.id === value)
                                                ?.name
                                        }
                                    </Button>
                                );
                            }}
                            sorter
                        />  
                        <Table.Column
                            key="description"
                            dataIndex="description"
                            title={t("sections.fields.description")}
                            render={(value) => <TextField value={value} />}
                        />                         
                        <Table.Column
                            key="created_at"
                            dataIndex="created_at"
                            title={t("sections.fields.created_at")}
                            render={(value) => (
                                <DateField value={value} format={t("format.date")} />
                            )}
                            sorter
                        />
                        <Table.Column<ISection>
                            dataIndex="section_actions"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id, record)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        /> 
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();
    
    const { selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { selectProps: layoutSelectProps } = useSelect<ILayout>({
        resource: "layouts",
        optionLabel: "layout_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { selectProps: typeSelectProps } = useSelect<ISectionType>({
        resource: "section-types",
        optionLabel: "name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Form layout="inline" {...formProps}>
            <Space size={[8, 8]} wrap align="end">
                <Form.Item label={t("search.keyword")} name="q">
                    <Input
                        placeholder="Section Name"
                        prefix={<SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item label="Type" name="type">
                    <Select
                        style={{ width: 130 }}
                        allowClear
                        {...typeSelectProps}
                        placeholder="Type"
                    />
                </Form.Item>
                <Form.Item label="Miniapps" name="miniapp_id">
                    <Select
                        style={{ width: 200 }}
                        {...miniappSelectProps}
                        allowClear
                        placeholder="Search Miniapps"
                    />
                </Form.Item>
                <Form.Item label="Layouts" name="layout_id">
                    <Select
                        {...layoutSelectProps}
                        allowClear
                        placeholder="Search Layouts"
                    />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">
                        {t("buttons.filter")}
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    );
};

const expandedRowRender = (record: ISection) => {

    if (record.type == 3 || record.type == 4) return <SectionBannersTable record={record} />;
    if (record.type == 5) return <SectionVouchersTable record={record} />;
    
    return <SectionMiniappsTable record={record} />;
};