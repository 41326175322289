import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { 
    useForm, 
    Edit,
    useSelect, 
} from "@refinedev/antd";

import { Select, Button, Form, Input, Space, Avatar, Alert } from "antd";

interface IFormValue {
    text_uuid?: string;
    table_name?: string;
    languages?: any;
    colum_names?: any;
    original_text?: any;
    tranlates?: any;
}


export const AppstoreTranslateEdit: React.FC<IResourceComponentsProps> = () => {

    const {
        queryResult,
        form, 
        formProps, 
        saveButtonProps 
    } = useForm<IFormValue>({
        warnWhenUnsavedChanges: true,
    });

    const postData = queryResult?.data?.data;
    const languages = postData?.languages ?? {};
    const columNames = postData?.colum_names ?? {};
    const tranlates = postData?.tranlates ?? {};
    const originalText = postData?.original_text ?? {};

    return (
        <Edit title={`Translate ${postData?.table_name}`} saveButtonProps={saveButtonProps}>
            <Form 
                {...formProps} 
                layout="vertical"
            >
                <Form.Item name={"table_name"} hidden >
                    <Input type="hidden" />
                </Form.Item> 
                {Object.keys(columNames).map(function(colum_name, index) {
                    return (
                        <>
                            <Form.Item label={columNames[colum_name]}>
                            {Object.keys(languages).map(function(language_id, index) {

                                const item = languages[language_id];
                                var disabled = (language_id == "1")?true:false;
                                var initialValue = tranlates[colum_name]?tranlates[colum_name][language_id]:'';
                                if (language_id == "2") {
                                    initialValue = originalText[colum_name]?originalText[colum_name]:'';
                                }

                                return (
                                    <>
                                        <Form.Item
                                            name={`${colum_name}[${language_id}]`}
                                            style={{ maxWidth: "893px" }} 
                                            rules={[
                                            ]}
                                            initialValue={initialValue}
                                            hasFeedback
                                        >
                                            <Input  
                                                prefix={<Avatar
                                                    size={16}
                                                    src={`/images/flags/${item.code}.svg`}
                                                />} 
                                            />
                                        </Form.Item>
                                    </>
                                )
                            })}
                            </Form.Item>
                        </>
                    )
                })}
            </Form>
        </Edit>
    );
}
