import React, { useState, useEffect } from 'react';
import { useTranslate, useDelete, useNavigation } from "@refinedev/core";

import {
    List,
    CreateButton,
    TextField,
    BooleanField,
    useTable,
    useDrawerForm,
} from "@refinedev/antd";

import { 
    FormOutlined, 
    SearchOutlined, 
    MoreOutlined, 
    EditOutlined, 
    CloseCircleOutlined,
    CopyOutlined,
    GlobalOutlined, 
} from "@ant-design/icons";

import {
    Table,
    Space,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    //Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
    Grid,
    Image,
    Tag,
} from "antd";

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
    ILayout,
    IILayoutFilterVariables,
    ILayoutSection,
    ISectionType,
    IIdentity,
    ISection,
    IMiniapp,
    IMiniappSection,
} from "interfaces";

import { 
    EditMiniappOfSection,
    CreateMiniappOfSection, 
} from "components/miniappOfSection";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowDrag = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

export const LayoutMiniappsTable: React.FC<{ record: ILayoutSection }> = ({ record }) => {
    const t = useTranslate();
    const { list, edit } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const { sorter, tableQueryResult, tableProps: postTableProps } = useTable<IMiniappSection>({
        resource: "section-miniapps",
        pagination: {
            mode: "off",
        },
        permanentFilter: [
            {
                field: "section_id",
                operator: "eq",
                value: record.dashboard_section_id,
            },
        ],
        syncWithLocation: false,
    });

    const [dataSource, setDataSource] = useState<IMiniappSection[]>([])
    
    useEffect(() => {
        setDataSource(tableQueryResult?.data?.data ?? []);
    },[tableQueryResult?.data?.data])

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IMiniappSection>({
        action: "create",
        resource: "section-miniapps",
        redirect: false,
    });
    
    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IMiniappSection>({
        action: "edit",
        resource: "section-miniapps",
        redirect: false,
    });

    const moreMenu = (record: IMiniappSection) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="edit"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    editShow(record.id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="remove"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "section-miniapps",
                        id: record.id,
                        mutationMode: "undoable",
                    });
                }}
            >
                {"Remove"}
            </Menu.Item>
            <Menu.Item
                key="translate"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <GlobalOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    edit("translates", `misection${record.dssmi_text_uuid}`);
                }}
            >
                {"Translate"}
            </Menu.Item>
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            console.log(active, over);
            
            setDataSource((prev) => {
                const activeIndex = prev.findIndex((i) => i.id === active.id);
                const overIndex = prev.findIndex((i) => i.id === over?.id);

                var newArr = arrayMove(prev, activeIndex, overIndex); 
                console.log(newArr, prev, activeIndex, overIndex);
                return newArr
            });
            
        }
    };

    return (
        <List 
            title={t("miniappOfSection.titles.list_miniapp", {section_name: record.section_name})}  
            breadcrumb={null}
            headerButtons={({ createButtonProps }) => (
                <>
                    <CreateButton 
                        hideText={true}
                        onClick={() => createShow()}
                        meta={{ section_id: record.id }}
                    />
                </>
            )}
        >
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                // rowKey array
                items={(tableQueryResult?.data?.data  ?? []).map((i) => i.id)}
                strategy={verticalListSortingStrategy}
            >
            <Table 
                {...postTableProps}
                dataSource={dataSource}
                components={{
                    body: {
                      row: RowDrag,
                    },
                }}
                rowKey="id" 
            >
                <Table.Column
                    key="icon"
                    dataIndex="icon"
                    title={"Icon"}
                    render={(value) => <Avatar size={74} src={value} />}
                />      
                <Table.Column<IMiniappSection>
                    key="miniapp_name"
                    dataIndex="miniapp_name"
                    title={t("miniappOfSection.fields.miniapp_name")}
                    render={(value, record) => {
                        return (
                            <Button 
                                type="link"
                                target="_blank"
                                onClick={() => editShow(record.id)}
                            >
                                {value}
                            </Button>
                        )
                    }}
                />
                <Table.Column
                    key="shortcut_name"
                    dataIndex="shortcut_name"
                    title={"Shortcut Name"}
                    render={(value, record) => {
                        return (
                            <Tag color="magenta">
                                {value}
                            </Tag>
                        )
                    }}
                />              
                <Table.Column
                    key="ordering"
                    dataIndex="ordering"
                    title={t("miniappOfSection.fields.ordering")} 
                    align="center"
                    sorter
                />
                <Table.Column
                    key="status"
                    dataIndex="status"
                    title={t("miniappOfSection.fields.status")}
                    align="center"
                    render={(value) => (
                        <BooleanField
                            value={value === 1}
                            trueIcon={<TrueIcon />}
                            falseIcon={<FalseIcon />}
                            valueLabelTrue="Active"
                            valueLabelFalse="Inactive"
                        />
                    )}
                />
                <Table.Column
                    key="miniapp_status"
                    dataIndex="miniapp_status"
                    title={t("miniappOfSection.fields.miniapp_status")}
                    align="center"
                    render={(value) => (
                        <BooleanField
                            value={value === 1}
                            trueIcon={<TrueIcon />}
                            falseIcon={<FalseIcon />}
                            valueLabelTrue="Active"
                            valueLabelFalse="Inactive"
                        />
                    )}
                />     
                <Table.Column<IMiniappSection>
                    dataIndex="miniapp_actions"
                    title={t("table.actions")}
                    render={(_, record) => (
                        <Dropdown
                            overlay={moreMenu(record)}
                            trigger={["click"]}
                        >
                            <MoreOutlined
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>
            </SortableContext>
        </DndContext>
            <CreateMiniappOfSection
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                sectionId={record.dashboard_section_id}
            />
            <EditMiniappOfSection
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />
        </List>
    );
};