export const DecreaseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
    props,
) => (
    <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.0871731 0.596576L5.68467 7.08722C5.84489 7.27301 6.1534 7.27301 6.31533 7.08722L11.9128 0.596577C12.1208 0.354542 11.9333 1.04846e-05 11.5975 1.04552e-05L0.402501 9.47653e-06C0.0667187 9.44718e-06 -0.120774 0.354541 0.0871731 0.596576Z"
            fill="#D80027"
        />
    </svg>
);
