import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useDelete,
} from "@refinedev/core";

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    BooleanField,
} from "@refinedev/antd";

import { FormOutlined, SearchOutlined, MoreOutlined, EditOutlined, CloseCircleOutlined, GlobalOutlined } from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
} from "antd";

import dayjs from "dayjs";

import {
    IMiniapp,
    IMiniappFilterVariables,
    IIdentity,
} from "interfaces";
import { useMemo } from "react";

export const AppstorePermissionList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, searchFormProps, filters } = useTable<
        IMiniapp,
        HttpError,
        IMiniappFilterVariables
    >({
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, app_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "app_id",
                    operator: "eq",
                    value: app_id,
                },
            );
            return filters;
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const moreMenu = (id: number, record: any) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="translate"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <GlobalOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    edit("translates", `permission${record.text_uuid}`);
                }}
            >
                {"Translate"}
            </Menu.Item>  
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <List title={t("permissions.titles.list")} breadcrumb={null} >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    // show("miniapp-versions", record.id);
                                },
                            };
                        }}
                    >
                        <Table.Column
                            key="id"
                            dataIndex="id"
                            title={"ID"}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />      
                        <Table.Column
                            key="name"
                            dataIndex="name"
                            title={t("permissions.fields.name")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />             
                        <Table.Column
                            key="description"
                            dataIndex="description"
                            title={t("permissions.fields.description")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />   
                        <Table.Column<IMiniapp>
                            dataIndex="miniapp_actions"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id, record)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />  
                    </Table>
                </List>
            </Col>
        </Row>
    );
};