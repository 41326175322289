import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useDelete,
} from "@refinedev/core";

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    BooleanField,
} from "@refinedev/antd";

import { FormOutlined, SearchOutlined, MoreOutlined, EditOutlined, CloseCircleOutlined } from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
} from "antd";

import dayjs from "dayjs";

import {
    IUser,
    IUserFilterVariables,
    IIdentity,
} from "interfaces";
import { useMemo } from "react";

export const AppstoreUserList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, searchFormProps, filters } = useTable<
        IUser,
        HttpError,
        IUserFilterVariables
    >({
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, user_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "user_id",
                    operator: "eq",
                    value: user_id,
                },
            );
            return filters;
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("users", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item> 
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("developers.titles.list")} breadcrumb={null} >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    // show("miniapp-versions", record.id);
                                },
                            };
                        }}
                    >
                        <Table.Column
                            key="id"
                            dataIndex="id"
                            title={"ID"}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />
                        <Table.Column
                            key="username"
                            dataIndex="username"
                            title={t("developers.fields.username")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />             
                        <Table.Column
                            key="full_name"
                            dataIndex="full_name"
                            title={t("developers.fields.full_name")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />  
                        <Table.Column
                            key="phone_number"
                            dataIndex="phone_number"
                            title={t("developers.fields.phone_number")}
                            render={(value) => <TextField value={value} />}
                        />      
                        <Table.Column
                            key="email"
                            dataIndex="email"
                            title={t("developers.fields.email")}
                            render={(value) => <TextField value={value} />}
                        />      
                        <Table.Column
                            key="status"
                            dataIndex="status"
                            title={t("developers.fields.status")}
                            align="center"
                            render={(value) => (
                                <BooleanField
                                    value={value === 1}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}
                                    valueLabelTrue="Active"
                                    valueLabelFalse="Inactive"
                                />
                            )}
                        />    
                        <Table.Column
                            key="note"
                            dataIndex="note"
                            title={t("developers.fields.note")}
                            render={(value) => <TextField value={value} />}
                        />   
                        <Table.Column<IUser>
                            dataIndex="users_actions"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />  
                    </Table>
                </List>
            </Col>
        </Row>
    );
};


const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label={t("search.keyword")} name="q">
                <Input
                    placeholder="User Name, Full Name"
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="User ID" name="user_id">
                <Input
                    placeholder="User ID"
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t("buttons.filter")}
                </Button>
            </Form.Item>
        </Form>
    );
};