import { useTranslate, useDelete, useNavigation } from "@refinedev/core";

import {
    List,
    CreateButton,
    TextField,
    BooleanField,
    useTable,
    useDrawerForm,
} from "@refinedev/antd";

import { 
    FormOutlined, 
    SearchOutlined, 
    MoreOutlined, 
    EditOutlined, 
    CloseCircleOutlined,
    CopyOutlined,
    GlobalOutlined, 
} from "@ant-design/icons";

import {
    Table,
    Space,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
    Grid,
    Image,
    Tag,
} from "antd";

import {
    ILayout,
    IILayoutFilterVariables,
    ILayoutSection,
    ISectionType,
    IIdentity,
    ISection,
    IMiniapp,
    IMiniappSection,
} from "interfaces";

import { 
    EditMiniappOfSection,
    CreateMiniappOfSection, 
} from "components/miniappOfSection";

export const SectionMiniappsTable: React.FC<{ record: ISection }> = ({ record }) => {
    const t = useTranslate();

    const { sorter, tableProps: postTableProps } = useTable<IMiniappSection>({
        resource: "section-miniapps",
        pagination: {
            mode: "off",
        },
        permanentFilter: [
            {
                field: "section_id",
                operator: "eq",
                value: record.id,
            },
        ],
        syncWithLocation: false,
    });

    const { list } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IMiniappSection>({
        action: "create",
        resource: "section-miniapps",
        redirect: false,
    });
    
    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IMiniappSection>({
        action: "edit",
        resource: "section-miniapps",
        redirect: false,
    });

    const moreMenu = (record: IMiniappSection) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="edit"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    editShow(record.id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="remove"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "section-miniapps",
                        id: record.id,
                        mutationMode: "undoable",
                    });
                }}
            >
                {t("buttons.delete")}
            </Menu.Item>
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <List 
            title={`MiniApps Of ${record.section_name}`}  
            breadcrumb={null}
            headerButtons={({ createButtonProps }) => (
                <>
                    <CreateButton 
                        hideText={true}
                        onClick={() => createShow()}
                        meta={{ section_id: record.id }}
                    />
                </>
            )}
        >
            <Table {...postTableProps} rowKey="id">
                <Table.Column
                    key="icon"
                    dataIndex="icon"
                    title={"Icon"}
                    render={(value) => <Avatar size={74} src={value} />}
                />      
                <Table.Column
                    key="miniapp_name"
                    dataIndex="miniapp_name"
                    title={"Miniapp Name"}
                    render={(value) => {
                        return (
                            <Button 
                                type="link"
                                target="_blank"
                                onClick={() => list("miniapps")}
                            >
                                {value}
                            </Button>
                        )
                    }}
                />
                <Table.Column
                    key="shortcut_name"
                    dataIndex="shortcut_name"
                    title={"Shortcut Name"}
                    render={(value, record) => {
                        return (
                            <Tag color="magenta">
                                {value}
                            </Tag>
                        )
                    }}
                />         
                <Table.Column
                    key="ordering"
                    dataIndex="ordering"
                    title={"Ordering"} 
                    align="center"
                    sorter
                />
                <Table.Column
                    key="status"
                    dataIndex="status"
                    title={"Status"}
                    align="center"
                    render={(value) => (
                        <BooleanField
                            value={value === 1}
                            trueIcon={<TrueIcon />}
                            falseIcon={<FalseIcon />}
                            valueLabelTrue="Active"
                            valueLabelFalse="Inactive"
                        />
                    )}
                /> 
                <Table.Column
                    key="miniapp_status"
                    dataIndex="miniapp_status"
                    title={"Miniapp Status"}
                    align="center"
                    render={(value) => (
                        <BooleanField
                            value={value === 1}
                            trueIcon={<TrueIcon />}
                            falseIcon={<FalseIcon />}
                            valueLabelTrue="Active"
                            valueLabelFalse="Inactive"
                        />
                    )}
                />     
                <Table.Column<IMiniappSection>
                    dataIndex="miniapp_actions"
                    title={t("table.actions")}
                    render={(_, record) => (
                        <Dropdown
                            overlay={moreMenu(record)}
                            trigger={["click"]}
                        >
                            <MoreOutlined
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>

            <CreateMiniappOfSection
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                sectionId={record.id}
            />
            <EditMiniappOfSection
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />

        </List>
    );
};