import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { useForm, Create } from "@refinedev/antd";

import { Select, Button, Form, Input, Space, Radio } from "antd";

interface IFormValue {
    id?: number;
    layout_id?: number;
    package_name: string;
    hostapp_name: string;
    status: number;
    note: string;
}

type FormValues = Omit<IFormValue, "id">;

export const MiniDashboardLayoutCreate: React.FC<IResourceComponentsProps> = () => {

    const { formLoading, onFinish, queryResult, formProps, saveButtonProps } = useForm<IFormValue, HttpError, FormValues>();
    const defaultValues = queryResult?.data?.data;

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form 
                {...formProps} 
                layout="vertical"
                initialValues={{
                    status: 1,
                    ...formProps.initialValues,
                }}
            >
                <Form.Item
                    name={"id"}
                    hidden
                >
                    <Input type="hidden" />
                </Form.Item>      
                
                <Form.Item
                    name={"layout_name"}
                    label="Layout Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter layout name" },
                        {
                            whitespace: true,
                        },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>                
                <Form.Item
                    name={"status"}
                    label="Status"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Radio.Group>
                        <Radio value={1}>
                            {"Active"}
                        </Radio>
                        <Radio value={2}>
                            {"Inactive"}
                        </Radio>
                    </Radio.Group> 
                </Form.Item>             
                <Form.Item
                    name={"note"}
                    label="Note"
                    style={{ maxWidth: "893px" }}
                >
                    <Input.TextArea />
                </Form.Item> 
                 
            </Form>
        </Create>
    );
}
