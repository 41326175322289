import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
    useShow,
} from "@refinedev/core";

import {
    Create,
    SaveButton,
    getValueFromEvent,
    useStepsForm,
    useSelect,
    List, 
    useTable
} from "@refinedev/antd"; 

import {
    EnvironmentOutlined,
    PhoneOutlined,
    MailOutlined,
    BankOutlined,
    HomeOutlined,
    CarOutlined,
    StarOutlined,
} from "@ant-design/icons";

import {
    Form,
    Select,
    Upload,
    Input,
    Button,
    Steps,
    Typography,
    Space,
    Avatar,
    Row,
    Col,
    InputProps,
    Card,
    Table,
} from "antd";

const { Text } = Typography;

import { IMiniapp, IMiniappPermission } from "interfaces";

const { Step } = Steps;


export const AppstoreMiniappShow: React.FC = () => {
    const t = useTranslate();
    const { queryResult: miniappQueryResult } = useShow<IMiniapp>();
    const miniappInfo = miniappQueryResult.data?.data;
 
    const { tableProps } = useTable<IMiniappPermission>({
        resource: "miniapp_permissions",
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        permanentFilter: [
            {
                field: "miniapp_id",
                operator: "eq",
                value: miniappInfo?.id,
            },
        ],
        initialPageSize: 4,
        queryOptions: {
            enabled: miniappInfo !== undefined,
        },
        syncWithLocation: false,
    });

    return (
        <Row gutter={[16, 16]}>
            <Col xl={9} lg={24} xs={24}>
                <Card bordered={false} style={{ height: "100%" }}>
                    <Space
                        direction="vertical"
                        style={{ width: "100%", height: "100%" }}
                    >
                        <Space
                            direction="vertical"
                            style={{ textAlign: "center", width: "100%" }}
                        >
                            <Avatar
                                size={120}
                                src={miniappInfo?.icon?.[0].url}
                            ></Avatar>
                            <Typography.Title level={3}>
                                {miniappInfo?.miniapp_name}
                            </Typography.Title>
                        </Space>
                        <Space
                            direction="vertical"
                            style={{
                                width: "100%",
                                textAlign: "unset",
                            }}
                        >
                            <Typography.Text>
                                <EnvironmentOutlined /> {miniappInfo?.package_name}
                            </Typography.Text>                             
                            <Typography.Text>
                                <EnvironmentOutlined /> {miniappInfo?.app_id}
                            </Typography.Text>                            
                            <Typography.Text>
                                <EnvironmentOutlined /> {miniappInfo?.note}
                            </Typography.Text> 
                        </Space>
                    </Space>
                </Card>
            </Col>
            <Col xl={15} xs={24}>
                 <List
                    title="Permissions"
                    headerProps={{
                        extra: <></>,
                    }}
                >
                    <Table {...tableProps} rowKey="id">
                         
                        <Table.Column
                            width={250}
                            dataIndex="comment"
                            title={"Permission"}
                        /> 
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
