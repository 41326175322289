import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
} from "@refinedev/core";

import {
    Create,
    SaveButton,
    getValueFromEvent,
    useForm,
    useSelect,
} from "@refinedev/antd";

import {
    Form,
    Select,
    Upload,
    Input,
    Button,
    Steps,
    Typography,
    Space,
    Avatar,
    Row,
    Col,
    InputProps,
    ColorPicker,
    InputNumber,
} from "antd";

const { Text } = Typography;

import type { Color, ColorPickerProps } from 'antd/es/color-picker';
import React, { useMemo, useState, useEffect } from 'react';

import { IUser } from "interfaces";

const { Step } = Steps;


export const AppstoreUserCreate: React.FC = () => {
    const t = useTranslate();
    const {
        form,  
        saveButtonProps,
        formProps,
    } = useForm<IUser>();
    const apiUrl = useApiUrl();

    return (
        <Create
            title="Create User"
            saveButtonProps={saveButtonProps}
        >
            <Form
                {...formProps}
                layout="vertical"
                initialValues={{ username: '', new_password: '' }}
                autoComplete="off"
                scrollToFirstError
            >
                <Row gutter={20}>
                    <Col xs={24} lg={8}>
                        <Form.Item>
                            <Form.Item
                                name="icon"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                                noStyle
                            >
                                <Upload.Dragger
                                    name="file"
                                    action={`${process.env.REACT_APP_UPLOAD_API_URL}/upload/icon`}
                                    listType="picture"
                                    maxCount={1}
                                    multiple
                                    style={{
                                        border: "none",
                                        width: "100%",
                                        background: "none",
                                    }}
                                >
                                    <Space direction="vertical" size={2}>
                                        <Avatar
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                maxWidth: "200px",
                                            }}
                                            src="/images/user-default-img.png"
                                            alt="Avatar"
                                        />
                                        <Text
                                            style={{
                                                fontWeight: 800,
                                                fontSize: "16px",
                                                marginTop: "8px",
                                            }}
                                        >
                                            {"Avatar"}
                                        </Text>
                                        <Text style={{ fontSize: "12px" }}>
                                            {t("couriers.fields.images.validation")}
                                        </Text>
                                    </Space>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={16}>
                        <Row gutter={10}>
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label={"Full Name"}
                                    name="full_name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={"Phone Number"}
                                    name="phone_number"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={"User Name"}
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9\.]+$/,
                                            message: 'Name can only include letters and numbers.',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={"Email"}
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={"Password"}
                                    name="new_password" 
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    name="confirm"
                                    label="Confirm Password"
                                    dependencies={["new_password"]}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please confirm your password!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("new_password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "The new password that you entered do not match!",
                                                    ),
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    label="Status"
                                    name="status"
                                >
                                    <Select
                                        options={[
                                            {
                                                label: "Active",
                                                value: 1,
                                            },
                                            {
                                                label: "Inactive",
                                                value: 2,
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={"Note"}
                                    name="note"
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
            </Form>         
        </Create>
    );
};
