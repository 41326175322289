import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useList,
    useParsed,
    useShow,
} from "@refinedev/core";

import { 
    useForm, 
    Edit, 
    useSelect, 
    getValueFromEvent, 
} from "@refinedev/antd";


import { 
    Select,
    Upload, 
    Button, 
    Form, 
    Input, 
    Space,
    Row,
    Col, 
    Avatar,
    Typography,
} from "antd";

const { Text } = Typography;

interface IFormValue {
    package_name: string;
    hostapp_name: string;
    status: number;
    note: string;
}

import { IMiniapp, IPermission } from "interfaces";

export const AppstoreMiniAppVersionPermission: React.FC<IResourceComponentsProps> = () => {

    /*
    const { queryResult } = useShow<IPermission>();
    const { data, isLoading } = queryResult;
    const record = data?.data;*/

    const t = useTranslate();

    const { 
        queryResult,
        formProps, 
        saveButtonProps 
    } = useForm<
        IFormValue
    >();

    const postData = queryResult?.data?.data;

    const { data: permissions } = useList<IPermission>({
        resource: "permissions",
    });

    const parsed = useParsed();

    console.log(postData)
    //console.log(parsed)

    return (
        <Edit title="MiniApp Version Permissions" saveButtonProps={saveButtonProps}>
            <Form 
                {...formProps} 
                layout="vertical"
                initialValues={postData}
            >
            <Row gutter={[16, 16]}>
                {permissions?.data.map((permission) => (
                    <Col xs={8}>
                        <Form.Item
                            label={permission.name}
                            name={["permissions", permission.name]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: "Required",
                                        value: 1,
                                    },
                                    {
                                        label: "Optional",
                                        value: 2,
                                    },
                                    {
                                        label: "No",
                                        value: 0,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                ))} 
            </Row>
            </Form>
        </Edit>
    );
}
