import { useTranslate, useDelete, useNavigation } from "@refinedev/core";

import {
    List,
    CreateButton,
    TextField,
    BooleanField,
    useTable,
    useDrawerForm,
} from "@refinedev/antd";

import { 
    FormOutlined, 
    SearchOutlined, 
    MoreOutlined, 
    EditOutlined, 
    CloseCircleOutlined,
    CopyOutlined,
    GlobalOutlined, 
} from "@ant-design/icons";

import {
    Table,
    Space,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
    Grid,
    Image,
} from "antd";

import {
    ILayout,
    IILayoutFilterVariables,
    ILayoutSection,
    ISectionType,
    IIdentity,
    ISection,
    IMiniapp,
    IMiniappSection,
} from "interfaces";

import { 
    EditMiniappOfSection,
    CreateMiniappOfSection, 
} from "components/miniappOfSection";

export const SectionBannersTable: React.FC<{ record: ISection }> = ({ record }) => {
    const t = useTranslate();

    const { sorter, tableProps: postTableProps } = useTable<IMiniappSection>({
        resource: "section-miniapps",
        permanentFilter: [
            {
                field: "section_id",
                operator: "eq",
                value: record.id,
            },
        ],
        syncWithLocation: false,
    });

    const { list } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IMiniappSection>({
        action: "create",
        resource: "section-miniapps",
        redirect: false,
    });
    
    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IMiniappSection>({
        action: "edit",
        resource: "section-miniapps",
        redirect: false,
    });

    const moreMenu = (record: IMiniappSection) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="edit"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    editShow(record.id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="remove"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "section-miniapps",
                        id: record.id,
                        mutationMode: "undoable",
                    });
                }}
            >
                {t("buttons.delete")}
            </Menu.Item>
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <List 
            title={`Banners Of ${record.section_name}`}  
            breadcrumb={null}
            headerButtons={({ createButtonProps }) => (
                <>
                    <CreateButton 
                        hideText={true}
                        onClick={() => createShow()}
                        meta={{ section_id: record.id }}
                    />
                </>
            )}
        >
            <Table {...postTableProps} rowKey="id">
                <Table.Column
                    key="banner_image"
                    dataIndex="banner_image"
                    title={"Banner"}
                    render={(value) => <Image width={100} src={value} />}
                />                 
                <Table.Column<IMiniappSection>
                    key="link_url"
                    dataIndex="link_url"
                    title={"Url | MiniApp"}
                    render={(_, record) => {
                        if (record.banner_type == 2) {
                            return (
                                <Space>
                                    <Button type="link" target="_blank" href={record.link_url} icon={<GlobalOutlined />} />
                                    <TextField value={record.link_url} />
                                </Space>
                            )

                        } else {
                            return (
                                <Space>
                                    <Avatar size={74} src={record.icon} />
                                    <Button type="link"
                                        target="_blank"
                                        onClick={() => list("miniapps")}
                                    >
                                        {record.miniapp_name}
                                    </Button>
                                </Space>
                            )
                        }
                    }}
                />     
                <Table.Column
                    key="ordering"
                    dataIndex="ordering"
                    title={"Ordering"} 
                    align="center"
                    sorter
                />
                <Table.Column
                    key="status"
                    dataIndex="status"
                    title={"Status"}
                    align="center"
                    render={(value) => (
                        <BooleanField
                            value={value === 1}
                            trueIcon={<TrueIcon />}
                            falseIcon={<FalseIcon />}
                            valueLabelTrue="Active"
                            valueLabelFalse="Inactive"
                        />
                    )}
                /> 
                <Table.Column<IMiniappSection>
                    dataIndex="miniapp_actions"
                    title={t("table.actions")}
                    render={(_, record) => (
                        <Dropdown
                            overlay={moreMenu(record)}
                            trigger={["click"]}
                        >
                            <MoreOutlined
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>

            <CreateMiniappOfSection
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                sectionId={record.id}
            />
            <EditMiniappOfSection
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />

        </List>
    );
};
