import { useTranslate, useDelete, useNavigation } from "@refinedev/core";

import {
    List,
    CreateButton,
    TextField,
    BooleanField,
    useTable,
    useDrawerForm,
    NumberField,
} from "@refinedev/antd";

import { 
    FormOutlined, 
    SearchOutlined, 
    MoreOutlined, 
    EditOutlined, 
    CloseCircleOutlined,
    CopyOutlined,
    GlobalOutlined, 
} from "@ant-design/icons";

import {
    Table,
    Space,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
    Grid,
    Image,
} from "antd";

import {
    ILayout,
    IILayoutFilterVariables,
    ILayoutSection,
    ISectionType,
    IIdentity,
    ISection,
    IMiniapp,
    IMiniappSection,
} from "interfaces";

import { 
    EditMiniappOfSection,
    CreateMiniappOfSection, 
} from "components/miniappOfSection";

export const LayoutVouchersTable: React.FC<{ record: ILayoutSection }> = ({ record }) => {
    const t = useTranslate();

    const { sorter, tableProps: postTableProps } = useTable<IMiniappSection>({
        resource: "vouchers",
        permanentFilter: [
            {
                field: "section_id",
                operator: "eq",
                value: record.dashboard_section_id,
            },            
            {
                field: "is_voucher",
                operator: "eq",
                value: 1,
            },
        ],
        syncWithLocation: false,
    });

    const { mutate: mutateDelete } = useDelete();
    const { list } = useNavigation();

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<IMiniappSection>({
        action: "create",
        resource: "section-miniapps",
        redirect: false,
    });
    
    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<IMiniappSection>({
        action: "edit",
        resource: "section-miniapps",
        redirect: false,
    });

    return (
        <List 
            title={t("miniappOfSection.titles.list_voucher", {section_name: record.section_name})}  
            breadcrumb={null}
            headerButtons={({ createButtonProps }) => (
                <>
                     
                </>
            )}
        >
            <Table {...postTableProps} rowKey="id">
                <Table.Column
                    key="voucher_image"
                    dataIndex="voucher_image"
                    title={"Banner"}
                    render={(value) => <Image width={100} src={value} />}
                />          
                <Table.Column
                    key="title"
                    dataIndex="title"
                    title={"Title"} 
                />          
                <Table.Column
                    key="voucher_price"
                    dataIndex="voucher_price"
                    title={"Price"} 
                    align="center"
                    render={(value) => (
                        <NumberField value={value} />
                    )}
                    sorter
                />                 
                <Table.Column
                    key="point_to_exchange"
                    dataIndex="point_to_exchange"
                    title={"Point"} 
                    align="center"
                    render={(value) => (
                        <NumberField value={value} />
                    )}
                    sorter
                />             
                <Table.Column
                    key="category_id"
                    dataIndex="category_id"
                    title={"Category ID"} 
                    align="center"
                    sorter
                />      
                <Table.Column
                    key="start_date"
                    dataIndex="start_date"
                    title={"Start Date"} 
                    align="center"
                    sorter
                />    
                <Table.Column
                    key="end_date"
                    dataIndex="end_date"
                    title={"End Date"} 
                    align="center"
                    sorter
                /> 
            </Table> 
        </List>
    );
};
