import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelect, useForm, Edit } from "@refinedev/antd";

import { Select, Button, Form, Input, Space, Radio } from "antd";

interface IFormValue {
    layout_name: string;
    status: number;
    note: string;
}

import {
    ISectionType,
} from "interfaces";

export const MiniDashboardSectionEdit: React.FC<IResourceComponentsProps> = () => {

    const { selectProps: typeSelectProps } = useSelect<ISectionType>({
        resource: "section-types",
        optionLabel: "name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { formProps, saveButtonProps } = useForm<IFormValue>();
    return (
        <Edit title="Edit Section" saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    name={"section_name"}
                    label="Section Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter layout name" },
                        {
                            whitespace: true,
                        },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>                
                <Form.Item
                    name={"type"}
                    label="Type"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Select
                        {...typeSelectProps}
                    />
                </Form.Item>                      
                <Form.Item
                    name={"status"}
                    label="Status"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Radio.Group>
                        <Radio value={1}>
                            {"Active"}
                        </Radio>
                        <Radio value={2}>
                            {"Inactive"}
                        </Radio>
                    </Radio.Group> 
                </Form.Item>             
                <Form.Item
                    name={"description"}
                    label="Description"
                    style={{ maxWidth: "893px" }}
                >
                    <Input.TextArea />
                </Form.Item> 
                <Form.List name={"section_config"}>
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <Space
                                            key={field.key}
                                            direction="horizontal"
                                            style={{
                                                position: "relative",
                                                marginRight: "13px",
                                            }}
                                        >
                                            <Form.Item
                                                name={[field.name, "name"]}
                                                label={`Param Name - ${index + 1}`}
                                                style={{ width: "200px" }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "please enter a custom param",
                                                    },
                                                    {
                                                        whitespace: true,
                                                    },
                                                    {
                                                        min: 3,
                                                        message:
                                                            "field must be at least 3 characters",
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input placeholder="api-key" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[field.name, "value"]}
                                                label={`Param Value - ${index + 1}`}
                                                style={{ width: "400px" }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "please enter a custom param",
                                                    },
                                                    {
                                                        whitespace: true,
                                                    },
                                                    {
                                                        min: 1,
                                                        message:
                                                            "field must be at least 1 characters",
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input placeholder="api-secret" />
                                            </Form.Item>
                                            <Button
                                                danger
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                style={{ marginTop: "5px" }}
                                                icon={<DeleteOutlined />}
                                            ></Button>
                                        </Space>
                                    );
                                })}
                                <Form.Item>
                                    <Button
                                        icon={<PlusOutlined />}
                                        type="dashed"
                                        block
                                        style={{ maxWidth: "893px" }}
                                        onClick={() => add()}
                                    >
                                        Add a custom param
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>  
            </Form>
        </Edit>
    );
}
