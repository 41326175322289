import { AuthBindings, HttpError } from "@refinedev/core";
import { notification } from "antd";
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from "./constants";

import axios, { AxiosRequestConfig } from "axios";

export const authProvider = (API_URL: string): AuthBindings => {

    const axiosInstance = axios.create();

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const customError: HttpError = {
                ...error,
                message: error.response?.data?.message,
                statusCode: error.response?.status,
            };

            return Promise.reject(customError);
        },
    );

    axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (request.headers) {
            request.headers["Authorization"] = `Bearer ${token}`;
        } else {
            request.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        return request;
    });
    
    axiosInstance.defaults.baseURL = API_URL;

    return {

        login: async ({ email, password }) => {

            const response = await axiosInstance.post(`/login-by-email`, { email, password });

            if (response.status === 200 && response.data) {
                var resultData = response.data || {};

                if (resultData.error == 0) {
                    localStorage.setItem(TOKEN_KEY, `${resultData.data[0].token}`);
                    localStorage.setItem(REFRESH_TOKEN_KEY, `${resultData.data[0].refreshToken}`);
                    
                    return {
                        success: true,
                        redirectTo: "/",
                    };
                } else {
                    return {
                        success: false,
                        error: {
                            message: "Login failed",
                            name: resultData.message,
                        },
                    };
                } 
            } else {
                return {
                    success: false,
                    error: {
                        message: "Login failed",
                        name: "Invalid email or password",
                    },
                };
            }
        },
        updatePassword: async () => {
            notification.success({
                message: "Updated Password",
                description: "Password updated successfully",
            });
            return {
                success: true,
            };
        },
        forgotPassword: async ({ email }) => {
            notification.success({
                message: "Reset Password",
                description: `Reset password link sent to "${email}"`,
            });
            return {
                success: true,
            };
        },
        logout: async () => {
            localStorage.removeItem(TOKEN_KEY);
            return {
                success: true,
                redirectTo: "/login",
            };
        },
        onError: async (error) => {
            return { error };
        },
        check: async () => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (token) {
                return {
                    authenticated: true,
                };
            }

            return {
                authenticated: false,
                error: {
                    message: "Check failed",
                    name: "Token not found",
                },
                logout: true,
                redirectTo: "/login",
            };
        },
        getPermissions: async () => null,
        getIdentity: async () => {
            const token = localStorage.getItem(TOKEN_KEY);

            if (!token) {
                return null;
            }

            const response = await axiosInstance.post(`/profile`);

            if (response.status === 200 && response.data) {
                var userInfo = response?.data || {};

                if (userInfo) {
                    return {
                        id: userInfo.user_id,
                        name: userInfo.full_name ?? userInfo.username,
                        username: userInfo.username,
                        roles: userInfo.roles,
                        avatar: "/images/user-default-img.png",
                    };
                } else {
                    return null;
                } 
            } else {
                return null;
            }

            return {
                id: 1,
                name: "Admin..",
                avatar: "/images/user-default-img.png",
            };
        },
    }
};
