import React, { useEffect } from "react";
import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    useDelete,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    GetManyResponse, useDeleteMany, useMany
} from "@refinedev/core";

import type { SelectProps } from 'antd';

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    useEditableTable,
    useDrawerForm,
    BooleanField,
} from "@refinedev/antd";

import { CloudUploadOutlined, DeleteOutlined, FormOutlined, SearchOutlined, MoreOutlined, EditOutlined, CloseCircleOutlined, CopyOutlined } from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Grid,
    Menu,
    Dropdown,
    Space,
} from "antd";

const { Option } = Select;

import dayjs from "dayjs";

import {
    IMiniapp,
    IMiniappVersion,
    IMiniappVersionFilterVariables,
    IIdentity,
} from "interfaces";
import { useMemo } from "react";

// https://refine.dev/blog/migrating-a-react-admin-application-to-refine/#migrating-listing-pages
export const AppstoreMiniAppVersionList: React.FC<IResourceComponentsProps> = () => {
    const { 
        tableProps, 
        sorter, 
        searchFormProps, 
        filters,
    } = useTable<
        IMiniappVersion,
        HttpError,
        IMiniappVersionFilterVariables
    >({
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, miniapp_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "miniapp_id",
                    operator: "eq",
                    value: miniapp_id,
                },
            );
            return filters;
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit, clone, list, push } = useNavigation();
    const { mutate: mutateDelete } = useDelete();

    const breakpoint = Grid.useBreakpoint();

    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("miniapp-versions", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="edit"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("miniapp-permissions", id);
                }}
            >
                {t("buttons.permission")}
            </Menu.Item>
            <Menu.Item
                key="clone"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CopyOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    clone("miniapp-versions", id);
                }}
            >
                {t("buttons.clone")}
            </Menu.Item>
        </Menu>
    );

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    // selected multi
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        [],
    );

    const handleSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: handleSelectChange,
        getCheckboxProps: (record: IMiniappVersion) => ({
            disabled: (record.sdks?.length > 0) // Column configuration not to be checked
        })
    };

    const { mutate, isLoading } = useDeleteMany<IMiniappVersion>();

    const deleteSelectedItems = () => {
        mutate(
            {
                resource: "miniapp-versions",
                ids: selectedRowKeys.map(String),
                mutationMode: "undoable",
            },
            {
                onSuccess: () => {
                    setSelectedRowKeys([]);
                },
            },
        );
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List 
                    title={t("miniappVersions.titles.list")} breadcrumb={null} 
                    headerProps={{
                        subTitle: hasSelected && (
                            <Button
                                type="text"
                                onClick={() => deleteSelectedItems()}
                                loading={isLoading}
                                icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                }
                            >
                                {t("buttons.delete")}
                            </Button>
                        ), 
                    }}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    // show("miniapp-versions", record.id);
                                },
                            };
                        }}
                        rowSelection={{
                            //type: 'radio',
                            ...rowSelection
                        }}
                        scroll={{ x: 'calc(500px + 70%)', y: 'calc(100vh - 385px)' }}
                    >
                        <Table.Column
                            key="icon"
                            dataIndex="icon"
                            title={"Icon"}
                            width="80px"
                            render={(value) => {
                                return (
                                    <Avatar size={44} src={value} />
                                )
                            }}
                        />                 
                        <Table.Column
                            key="miniapp_name"
                            dataIndex="miniapp_name"
                            title={t("miniappVersions.fields.miniapp_name")}
                            render={(value) => {
                                return (
                                    <Button 
                                        type="link"
                                        target="_blank"
                                        onClick={() => list("miniapps")}
                                    >
                                        {value}
                                    </Button>
                                )
                            }}
                            sorter
                        />       
                        <Table.Column<IMiniappVersion>
                            key="sdks"
                            dataIndex="sdks"
                            title={'SDKs'} 
                            width="100px"
                            render={(_, record) => {

                                return (
                                    <Popover
                                        content={
                                            <ul>
                                                {record.sdks?.map((sdk) => (
                                                    <li key={sdk.id}>
                                                        <Button 
                                                            type="link"
                                                            target="_blank"
                                                            onClick={() => list("sdks")}
                                                        >
                                                            {sdk.hostapp_name}
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                        title="SDKs"
                                        trigger="hover"
                                    >
                                        {t("orders.fields.itemsAmount", {
                                            amount: record.sdks?.length,
                                        })}
                                    </Popover>
                                )
                            }}
                        />     
                        <Table.Column
                            key="version_code"
                            dataIndex="version_code"
                            title={"Version Code"}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />       
                        <Table.Column
                            key="version_name"
                            dataIndex="version_name"
                            title={"Version Name"}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />          
                        <Table.Column
                            key="status"
                            dataIndex="status"
                            title={t("miniappVersions.fields.status")}
                            align="center"
                            render={(value) => (
                                <BooleanField
                                    value={value === 1}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}
                                    valueLabelTrue="Active"
                                    valueLabelFalse="Inactive"
                                />
                            )}
                        />         
                        <Table.Column
                            key="change_log"
                            dataIndex="change_log"
                            title={t("miniappVersions.fields.change_log")}
                            render={(value) => <TextField value={value} />}
                        />                         
                        <Table.Column
                            key="created_at"
                            dataIndex="created_at"
                            fixed="right"
                            width="150px"
                            title={t("miniappVersions.fields.created_at")}
                            render={(value) => (
                                <DateField value={value} format={t("format.date")} />
                            )}
                            sorter
                        /> 
                        <Table.Column<IMiniappVersion>
                            dataIndex="miniapp_actions"
                            fixed="right"
                            width="90px"
                            align="center"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />  
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();
    
    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_name,
            value: item.id,
            icon: item.icon,
        }
    });
   

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label={t("search.keyword")} name="q">
                <Input
                    placeholder="Version Name, Version Code"
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Miniapps" name="miniapp_id">
                <Select
                    {...miniappSelectProps}
                    allowClear
                    placeholder="Search Miniapps"
                    options={miniappOptions}
                    optionRender={(option) => { 
                        return (
                            <Space>
                                <span role="img" aria-label={option.data.label}>
                                    <Avatar size={20} src={option.data.icon} /> 
                                </span>
                                {option.data.label}
                            </Space>
                        )
                    }}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t("buttons.filter")}
                </Button>
            </Form.Item>
        </Form>
    );
};