import {
    IResourceComponentsProps,
    useTranslate,
    useApiUrl,
} from "@refinedev/core";

import {
    Edit,
    SaveButton,
    getValueFromEvent,
    useForm,
    useSelect,
    TextField,
} from "@refinedev/antd";

import {
    Form,
    Select,
    Upload,
    Input,
    Button,
    Steps,
    Typography,
    Space,
    Avatar,
    Row,
    Col,
    InputProps,
    ColorPicker,
    InputNumber,
    Radio,
} from "antd";

const { Text } = Typography;

import type { Color, ColorPickerProps } from 'antd/es/color-picker';
import React, { useMemo, useState, useEffect } from 'react';

import { IMiniapp } from "interfaces";

const { Step } = Steps;


export const AppstoreMiniappEdit: React.FC = () => {
    const t = useTranslate();
    const {
        form, formProps, saveButtonProps, queryResult 
    } = useForm<IMiniapp>();

    const miniappData = queryResult?.data?.data;
    
    const apiUrl = useApiUrl();

    const [formatHex, setFormatHex] = useState<ColorPickerProps['format']>('hex');
    const [colorHex, setColorHex] = useState<Color | string>(miniappData?.header_background?miniappData?.header_background:'#1677FF');

    const hexString = useMemo(
        () => (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()),
        [colorHex],
    );

    useEffect(() => {
        form.setFieldValue('header_background', (typeof colorHex === 'string' ? colorHex : colorHex?.toHexString()))
    }, [colorHex]);

    return (
        <Edit
            title="Edit MiniApp"
            saveButtonProps={saveButtonProps}
            headerProps={{
                extra:(
                    <Space wrap>
                         
                    </Space>
                )
            }}
        >
            <Form
                {...formProps}
                style={{ marginTop: 30 }}
                layout="vertical"
                initialValues={{
                    ...formProps.initialValues,
                    header_background: miniappData?.header_background,
                }}
            >
                <Row gutter={20}>
                    <Col xs={24} lg={8}>
                        <Form.Item>
                            <Form.Item
                                name="icon"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                                noStyle
                            >
                                <Upload.Dragger
                                    name="file"
                                    action={`${process.env.REACT_APP_UPLOAD_API_URL}/upload/icon`}
                                    listType="picture"
                                    maxCount={1}
                                    multiple
                                    style={{
                                        border: "none",
                                        width: "100%",
                                        background: "none",
                                    }}
                                >
                                    <Space direction="vertical" size={2}>
                                        <Avatar
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                maxWidth: "200px",
                                            }}
                                            src="/images/user-default-img.png"
                                            alt="Store Location"
                                        />
                                        <Text
                                            style={{
                                                fontWeight: 800,
                                                fontSize: "16px",
                                                marginTop: "8px",
                                            }}
                                        >
                                            {"MiniApp Icon"}
                                        </Text>
                                        <Text style={{ fontSize: "12px" }}>
                                            {t("couriers.fields.images.validation")}
                                        </Text>
                                    </Space>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={16}>
                        <Row gutter={10}>
                            <Col xs={12}>
                                <Form.Item
                                    label={"MiniApp Name"}
                                    name="miniapp_name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={"Package Name"}
                                    name="package_name"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9\.]+$/,
                                            message: 'Name can only include letters and numbers.',
                                        },
                                    ]}
                                >
                                    <Input  placeholder="a.b.c" />
                                </Form.Item>

                                <Form.Item
                                    label="Status"
                                    name="status"
                                >
                                    <Radio.Group>
                                        <Radio value={1}>
                                            {"Active"}
                                        </Radio>
                                        <Radio value={2}>
                                            {"Inactive"}
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                
                            </Col>
                            <Col xs={12}>

                                <Form.Item
                                    label={"Header Title"}
                                    name="header_title"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={"Header Background"}
                                    name="header_background"
                                >
                                    <ColorPicker
                                        format={formatHex}
                                        value={colorHex}
                                        onChange={setColorHex}
                                        onFormatChange={setFormatHex}
                                        showText
                                        disabledAlpha
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={"Header Foreground"}
                                    name="header_foreground"
                                >
                                    <Select
                                        options={[
                                            {
                                                label: "Black",
                                                value: 'black',
                                            },
                                            {
                                                label: "White",
                                                value: 'white',
                                            },
                                        ]}
                                    />
                                </Form.Item> 

                                
                               
                            </Col>
                        </Row> 
                        <Row gutter={20}>
                            <Col xs={12}>
                                <Form.Item
                                    label={"Provider Name"}
                                    name="provider_name"
                                    rules={[ 
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item
                                    label={"Provider Description"}
                                    name="provider_description"
                                    rules={[ 
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        }, 
                                    ]}
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col xs={12}>
                        <Form.Item
                            label={"Terms Link"}
                            name="terms_link"
                        >
                            <Input  placeholder="https://" />
                        </Form.Item>
                    </Col>

                    <Col xs={12}>
                        <Form.Item
                            label={"Privacy Policy Link"}
                            name="privacy_policy_link"
                        >
                            <Input  placeholder="https://" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={"Important Note"}
                    name="note"
                >
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Edit>
    );
};
