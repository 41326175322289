import { useTranslate, useApiUrl, BaseKey } from "@refinedev/core";

import type { SelectProps } from 'antd';
import { Edit, getValueFromEvent, useSelect, useForm } from "@refinedev/antd";

import {
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    InputNumber,
    Radio,
    Select,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    Button, 
} from "antd";

const { Text } = Typography;

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

interface IFormValue {
    layout_name: string;
    status: number;
    note: string;
}

import { IMiniapp, ISdk, IMiniappVersion } from "interfaces";

type EditLayoutSectionProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    editId?: BaseKey;
};

export const EditMiniappOfSDK: React.FC<EditLayoutSectionProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    editId,
}) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const breakpoint = Grid.useBreakpoint();

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniappVersion>({
        resource: "miniapp-versions",
        optionLabel: "miniapp_version",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });
    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_version,
            value: item.id,
            icon: item.icon,
        }
    });

    const { selectProps: sdkSelectProps } = useSelect<ISdk>({
        resource: "sdks",
        optionLabel: "hostapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.sm ? "500px" : "100%"}
            zIndex={1001}
        >
            <Edit
                title="Edit MiniApp Of SDK"
                saveButtonProps={saveButtonProps}
                resource="sdk-miniapps"
                recordItemId={editId}
                contentProps={{
                    style: {
                        boxShadow: "none",
                    },
                    bodyStyle: {
                        padding: 0,
                    },
                }}
            >
                <Form {...formProps} layout="vertical">
                    <Form.Item
                        name={"sdk_id"}
                        label="SDK Name"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select {...sdkSelectProps} />
                    </Form.Item>     
                    <Form.Item
                        name={"miniapp_version_id"}
                        label="MiniApp Version"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select 
                            {...miniappSelectProps}
                            options={miniappOptions}
                            optionRender={(option) => { 
                                return (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                            <Avatar size={20} src={option.data.icon} /> 
                                        </span>
                                        {option.data.label}
                                    </Space>
                                )
                            }} 
                        />
                    </Form.Item>
                    <Form.Item
                        label={"Ordering"}
                        name="ordering"
                        style={{ maxWidth: "893px" }}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>    
                    <Form.List name={"custom_metadata"}>
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.map((field, index) => {
                                        return (
                                            <Space
                                                key={field.key}
                                                direction="horizontal"
                                                style={{
                                                    position: "relative",
                                                    marginRight: "13px",
                                                }}
                                            >
                                                <Form.Item
                                                    name={[field.name, "name"]}
                                                    label={`Param Name - ${index + 1}`}
                                                    style={{ width: "200px" }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "please enter a custom metadata",
                                                        },
                                                        {
                                                            whitespace: true,
                                                        },
                                                        {
                                                            min: 3,
                                                            message:
                                                                "field must be at least 3 characters",
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input placeholder="api-key" />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[field.name, "value"]}
                                                    label={`Param Value - ${index + 1}`}
                                                    style={{ width: "400px" }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "please enter a custom metadata",
                                                        },
                                                        {
                                                            whitespace: true,
                                                        },
                                                        {
                                                            min: 1,
                                                            message:
                                                                "field must be at least 1 characters",
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input placeholder="api-secret" />
                                                </Form.Item>
                                                <Button
                                                    danger
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                    style={{ marginTop: "5px" }}
                                                    icon={<DeleteOutlined />}
                                                ></Button>
                                            </Space>
                                        );
                                    })}
                                    <Form.Item>
                                        <Button
                                            icon={<PlusOutlined />}
                                            type="dashed"
                                            block
                                            style={{ maxWidth: "893px" }}
                                            onClick={() => add()}
                                        >
                                            Add a custom metadata
                                        </Button>
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.List> 
                </Form>
                    
            </Edit>
        </Drawer>
    );
};
