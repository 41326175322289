import axios from "axios";
import { TOKEN_KEY } from "./../../../constants";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useGo,
    useApiUrl,
    useCustomMutation,
    useCustom,
} from "@refinedev/core";

import type { SelectProps } from 'antd';

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
} from "@refinedev/antd";

import { 
    SearchOutlined,
    EditOutlined,
    CloseCircleOutlined,
    MoreOutlined,
    CloudDownloadOutlined,
    CloudUploadOutlined,
} from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Menu,
    Dropdown,
    QRCode,
    Space,
    Popconfirm,
    Modal,
} from "antd";

import dayjs from "dayjs";

import {
    ISdk,
    IMiniappSdk,
    IMiniapp,
    ISdkFilterVariables,
    IIdentity,
} from "interfaces";
import { useMemo } from "react";

export const AppstoreMiniAppOfList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, searchFormProps, filters } = useTable<
        IMiniappSdk,
        HttpError,
        ISdkFilterVariables
    >({
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, sdk_id, miniapp_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "sdk_id",
                    operator: "eq",
                    value: sdk_id,
                },
                {
                    field: "miniapp_id",
                    operator: "eq",
                    value: miniapp_id,
                },
            );
            return filters;
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit, list } = useNavigation();
    const go = useGo();

    const API_URL = useApiUrl('appstore');
    const { mutate: mutatePublish } = useCustomMutation();

    // Publish to Prod
    const publishApp = (record?: IMiniappSdk) => {
        Modal.confirm({
            title: 'Xác nhận publish MiniApp',
            content: `Bạn muốn đưa phiên bản "${record?.version_code}" của "${record?.miniapp_name}" lên môi trường Production?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                mutatePublish({
                    url: `${API_URL}/sdk-miniapps/${record?.app_id}/publish-to-prod`,
                    method: "post",
                    values: {
                        sdk_miniapp_id: record?.id,
                        miniapp_version_id: record?.miniapp_version_id,
                    },
                });
            },
        });
        
    }

    const moreMenu = (id: number, record?: IMiniappSdk) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("sdk-miniapps", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            {/**  
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloudDownloadOutlined
                        style={{
                            color: "red",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    if (!window) {
                        throw new Error(
                            "Downloading only supported in a browser environment.",
                        );
                    }

                    const token = localStorage.getItem(TOKEN_KEY);
                    var headers = {Authorization: ''};
                    headers.Authorization = `Bearer ${token}`;

                    axios({
                        url: `${API_URL}/sdk-miniapps/${id}/${record?.miniapp_version_id}/package.zip`,
                        method: "GET",
                        headers: headers,
                        responseType: "blob" // important
                    }).then(response => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute(
                            "download",
                            `${record?.miniapp_name}${record?.version_code}.zip`
                        );
                        document.body.appendChild(link);
                        link.click();

                        // Clean up and remove the link
                        link.parentNode?.removeChild(link);
                    });
                }}
            >
                {t("Download")}
            </Menu.Item>
            */}

            <Menu.Item
                key="clone"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloudUploadOutlined
                        style={{
                            color: "red",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    publishApp(record);
                }}
            >
                {t("Publish")}
            </Menu.Item>

        </Menu>
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("miniappOfSDK.titles.list")} breadcrumb={null} >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    // show("miniapp-versions", record.id);
                                },
                            };
                        }}
                        scroll={{ x: 'calc(500px + 50%)', y: 'calc(100vh - 390px)' }}
                    >
            
                        <Table.Column<IMiniappSdk>
                            key="icon"
                            dataIndex="icon"
                            title={"Icon"}
                            width="100px"
                            render={(value, record) => {
                                return (
                                    <Popover 
                                    overlayInnerStyle={{ padding: 0 }} 
                                    content={
                                        <QRCode
                                            size={250}
                                            iconSize={40}
                                            errorLevel="H"
                                            value={`${process.env.REACT_APP_APPSTORE_API_URL}/sdk-miniapps/${record.id}/qr`}
                                            /*value={`supperapp://miniapp/dl/929e2b5c-156b-11ee-84df-02e37651594e`}*/
                                            icon={record.icon}
                                            bordered={false}
                                        />
                                    }>
                                        <Avatar size={74} src={value} />
                                    </Popover>
                                )
                            }}
                        />              
                        <Table.Column
                            key="miniapp_name"
                            dataIndex="miniapp_name"
                            title={t("miniappOfSDK.fields.miniapp_name")}
                            render={(value) => {
                                return (
                                    <Button 
                                        type="link"
                                        target="_blank"
                                        onClick={() => list("miniapps")}
                                    >
                                        {value}
                                    </Button>
                                )
                            }}
                            sorter
                        /> 
                        <Table.Column<IMiniappSdk>
                            key="version_name"
                            dataIndex="version_name"
                            title={t("miniappOfSDK.fields.version_name")}
                            width="150px"
                            align="center"
                            render={(value, record) => {
                                return (
                                    <Button 
                                        type="link"
                                        target="_blank"
                                        onClick={() => edit("sdk-miniapps", record.id)}
                                    >
                                        {value}
                                    </Button>
                                )
                            }}
                            sorter
                        />         
                        <Table.Column
                            key="ordering"
                            dataIndex="ordering"
                            title={t("miniappOfSDK.fields.ordering")}
                            width="120px"
                            align="center"
                            render={(value) => <TextField value={value} />}
                            sorter
                        />     
                        <Table.Column
                            key="hostapp_name"
                            dataIndex="hostapp_name"
                            title={t("miniappOfSDK.fields.hostapp_name")}
                            render={(value) => {
                                return (
                                    <Button 
                                        type="link"
                                        target="_blank"
                                        onClick={() => list("sdks")}
                                    >
                                        {value}
                                    </Button>
                                )
                            }}
                            sorter
                        />      
                        <Table.Column
                            key="modified_at"
                            dataIndex="modified_at"
                            title={t("miniappOfSDK.fields.modified_at")}
                            render={(value) => (
                                <DateField value={value} format={t("format.date")} />
                            )}
                            sorter
                        /> 
                        <Table.Column<IMiniappSdk>
                            dataIndex="actions"
                            fixed="right"
                            width="90px"
                            align="center"
                            title={t("table.actions")}
                            render={(_, record) => (
                                <Dropdown
                                    overlay={moreMenu(record.id, record)}
                                    trigger={["click"]}
                                >
                                    <MoreOutlined
                                        onClick={(e) => e.stopPropagation()}
                                        style={{
                                            fontSize: 24,
                                        }}
                                    />
                                </Dropdown>
                            )}
                        />        
                    </Table>
                </List>
            </Col>
        </Row>
    );
};


const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();
    
    const { selectProps: sdkSelectProps } = useSelect<ISdk>({
        resource: "sdks",
        optionLabel: "hostapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_name,
            value: item.id,
            icon: item.icon,
        }
    });

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label={t("search.keyword")} name="q">
                <Input
                    placeholder="Miniapp Name, Miniapp Package"
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Miniapps" name="miniapp_id">
                <Select
                    {...miniappSelectProps}
                    allowClear
                    placeholder="Search Miniapps"
                    options={miniappOptions}
                    optionRender={(option) => { 
                        return (
                            <Space>
                                <span role="img" aria-label={option.data.label}>
                                    <Avatar size={20} src={option.data.icon} /> 
                                </span>
                                {option.data.label}
                            </Space>
                        )
                    }}
                />
            </Form.Item>
            <Form.Item label="SDKs" name="sdk_id">
                <Select
                    {...sdkSelectProps}
                    allowClear
                    placeholder="Search SDKs"
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t("buttons.filter")}
                </Button>
            </Form.Item>
        </Form>
    );
};