import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { useSelect, useForm, Create } from "@refinedev/antd";

import { Select, Button, Form, Input, Space, Radio } from "antd";

interface IFormValue {
    package_name: string;
    hostapp_name: string;
    status: number;
    note: string;
}

import {
    ISectionType,
} from "interfaces";

export const MiniDashboardSectionCreate: React.FC<IResourceComponentsProps> = () => {

    const { selectProps: typeSelectProps } = useSelect<ISectionType>({
        resource: "section-types",
        optionLabel: "name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { formProps, saveButtonProps } = useForm<IFormValue>();
    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form 
                {...formProps} 
                layout="vertical"
                initialValues={{ 
                    status: 1,
                    ...formProps.initialValues, 
                }}
            >
                <Form.Item
                    name={"id"}
                    hidden
                >
                    <Input type="hidden" />
                </Form.Item>     
                <Form.Item
                    name={"section_name"}
                    label="Section Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter name" },
                        {
                            whitespace: true,
                        },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>                
                <Form.Item
                    name={"type"}
                    label="Type"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Select
                        {...typeSelectProps}
                    /> 
                </Form.Item>                    
                <Form.Item
                    name={"status"}
                    label="Status"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Radio.Group>
                        <Radio value={1}>
                            {"Active"}
                        </Radio>
                        <Radio value={2}>
                            {"Inactive"}
                        </Radio>
                    </Radio.Group>  
                </Form.Item>             
                <Form.Item
                    name={"description"}
                    label="Description"
                    style={{ maxWidth: "893px" }}
                >
                    <Input.TextArea />
                </Form.Item> 
                 
            </Form>
        </Create>
    );
}
