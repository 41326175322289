import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useDelete,
} from "@refinedev/core";

import {
    List,
    TextField,
    useTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    BooleanField,
    useModalForm,
    EditButton,
} from "@refinedev/antd";

import { FormOutlined, SearchOutlined, MoreOutlined, EditOutlined, CloseCircleOutlined } from "@ant-design/icons";

import {
    Table,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
    Modal,
    Spin,
    Space,
} from "antd";

import dayjs from "dayjs";

import {
    ITranslate,
    ITranslateFilterVariables,
    IIdentity,
} from "interfaces";

import { useMemo, useState } from "react";

export const AppstoreTranslateList: React.FC<IResourceComponentsProps> = () => {

    // === MODAL
    // Edit Modal
    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        formLoading: editFormLoading,
    } = useModalForm<ITranslate>({
        action: "edit",
        syncWithLocation: true,
    });

    // Show Modal
    const [visibleShowModal, setVisibleShowModal] = useState<boolean>(false);

    // === Filters
    const { tableProps, sorter, searchFormProps, filters } = useTable<
        ITranslate,
        HttpError,
        ITranslateFilterVariables
    >({
        initialSorter: [
            {
                field: "modified_at",
                order: "desc",
            },
        ],
        queryOptions: {
            retry: 3,
        },
        meta: {
            headers: { "x-meta-data": "true", "token":"123" },
            method: 'get',
        },
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, text_uuid } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "text_uuid",
                    operator: "eq",
                    value: text_uuid,
                },
            );
            return filters;
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit } = useNavigation();

    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("users", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item> 
        </Menu>
    );

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("translates.titles.list")} breadcrumb={null} >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    // show("miniapp-versions", record.id);
                                },
                            };
                        }}
                        scroll={{ x: 'calc(500px + 70%)', y: 'calc(100vh - 385px)' }}
                    >
                        
                        <Table.Column
                            key="text_uuid"
                            dataIndex="text_uuid"
                            title={"UUID"}
                            width="180px"
                            render={(value) => <TextField value={value} />}
                        />      
                        <Table.Column<ITranslate>
                            key="language_id"
                            dataIndex="language_id"
                            title={"Language"}
                            width="150px"
                            render={(value, record) => (
                                <Space>
                                    <Avatar
                                        size={16}
                                        src={`/images/flags/${record.language?.code}.svg`}
                                    />
                                    <TextField value={record.language?.name} />
                                </Space>
                            )}
                        />      
                        <Table.Column
                            key="content"
                            dataIndex="content"
                            title={"Content"}
                            render={(value) => <TextField value={value} />}
                        />      
                        <Table.Column
                            key="column_name"
                            dataIndex="column_name"
                            title={"Column Name"}
                            width="200px"
                            render={(value) => <TextField value={value} />}
                            sorter
                        />  
                        <Table.Column
                            key="table_name"
                            dataIndex="table_name"
                            title={"Table Name"}
                            width="200px"
                            render={(value) => <TextField value={value} />}
                            sorter
                        />             
                        <Table.Column
                            fixed="right"
                            width="130px" 
                            key="modified_at"
                            dataIndex="modified_at"
                            title={"Updated At"}
                            render={(value) => (
                                <DateField value={value} format={t("format.date")} />
                            )}
                            sorter
                        />
                        <Table.Column<ITranslate>
                            fixed="right"
                            width="90px"
                            align="center"
                            title="Actions"
                            dataIndex="actions"
                            key="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.text_uuid}
                                        onClick={() => edit('translates', record.text_uuid)}
                                    />
                                </Space>
                            )}
                        />   
                    </Table>
                </List>

                {/* Update Or Insert */}
                <Modal {...editModalProps}>
                    <Spin spinning={editFormLoading}>
                        <Form {...editFormProps} layout="vertical">
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item> 
                        </Form>
                    </Spin>
                </Modal>
            </Col>
        </Row>
    );
};


const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();

    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label={t("search.keyword")} name="q">
                <Input
                    placeholder="Column Name, Table Name"
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Text UUID" name="text_uuid">
                <Input
                    placeholder="Text UUID"
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t("buttons.filter")}
                </Button>
            </Form.Item>
        </Form>
    );
};