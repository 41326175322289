import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { useForm, useSelect, Create } from "@refinedev/antd";

import type { SelectProps } from 'antd';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Select, Button, Form, Input, Space, InputNumber } from "antd";

interface IFormValue {
    package_name: string;
    hostapp_name: string;
    status: number;
    note: string;
}

import { IMiniapp, ISdk, IMiniappVersion } from "interfaces";

export const AppstoreMiniAppOfCreate: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps } = useForm<IFormValue>();

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniappVersion>({
        resource: "miniapp-versions",
        optionLabel: "miniapp_version",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_version,
            value: item.id,
            icon: item.icon,
        }
    });

    const { selectProps: sdkSelectProps } = useSelect<ISdk>({
        resource: "sdks",
        optionLabel: "hostapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Create title="Create Miniapps Of SDK" saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    name={"sdk_id"}
                    label="SDK Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...sdkSelectProps} />
                </Form.Item>     
                <Form.Item
                    name={"miniapp_version_id"}
                    label="MiniApp Version"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...miniappSelectProps} 
                        options={miniappOptions}
                        optionRender={(option) => { 
                            return (
                                <Space>
                                    <span role="img" aria-label={option.data.label}>
                                        <Avatar size={20} src={option.data.icon} /> 
                                    </span>
                                    {option.data.label}
                                </Space>
                            )
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={"Ordering"}
                    name="ordering"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>    
                
                <Form.Item
                    name={"note"}
                    label="Note"
                    style={{ maxWidth: "893px" }}
                >
                    <Input.TextArea />
                </Form.Item> 

                <Form.List name={"custom_metadata"}>
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                {fields.map((field, index) => {
                                    return (
                                        <Space
                                            key={field.key}
                                            direction="horizontal"
                                            style={{
                                                position: "relative",
                                                marginRight: "13px",
                                            }}
                                        >
                                            <Form.Item
                                                name={[field.name, "name"]}
                                                label={`Field Name - ${index + 1}`}
                                                style={{ width: "200px" }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "please enter a custom metadata",
                                                    },
                                                    {
                                                        whitespace: true,
                                                    },
                                                    {
                                                        min: 3,
                                                        message:
                                                            "field must be at least 3 characters",
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input placeholder="api-key" />
                                            </Form.Item>
                                            <Form.Item
                                                name={[field.name, "value"]}
                                                label={`Field Value - ${index + 1}`}
                                                style={{ width: "400px" }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "please enter a custom metadata",
                                                    },
                                                    {
                                                        whitespace: true,
                                                    },
                                                    {
                                                        min: 1,
                                                        message:
                                                            "field must be at least 1 characters",
                                                    },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input placeholder="api-secret" />
                                            </Form.Item>
                                            <Button
                                                danger
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                                style={{ marginTop: "5px" }}
                                                icon={<DeleteOutlined />}
                                            ></Button>
                                        </Space>
                                    );
                                })}
                                <Form.Item>
                                    <Button
                                        icon={<PlusOutlined />}
                                        type="dashed"
                                        block
                                        style={{ maxWidth: "893px" }}
                                        onClick={() => add()}
                                    >
                                        Add a custom metadata
                                    </Button>
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
                 
            </Form>
        </Create>
    );
}
