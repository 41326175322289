import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import type { SelectProps } from 'antd';
import { 
    useForm, 
    Edit, 
    useSelect, 
    getValueFromEvent, 
} from "@refinedev/antd";


import { 
    Select,
    Upload, 
    Button, 
    Form, 
    Input, 
    Space,
    Row,
    Col, 
    Avatar,
    Typography,
    Radio,
} from "antd";

const { Text } = Typography;

interface IFormValue {
    package_name: string;
    hostapp_name: string;
    status: number;
    note: string;
}

import { IMiniapp } from "interfaces";

export const AppstoreMiniAppVersionEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { formProps, saveButtonProps } = useForm<IFormValue>();

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_name,
            value: item.id,
            icon: item.icon,
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Row gutter={20}>
                    <Col xs={24} lg={8}>
                        <Form.Item>
                            <Form.Item
                                name="zip"
                                valuePropName="fileList"
                                getValueFromEvent={getValueFromEvent}
                                noStyle
                            >
                                <Upload.Dragger
                                    name="file"
                                    action={`${process.env.REACT_APP_UPLOAD_API_URL}/upload/app`}
                                    listType="picture"
                                    maxCount={1}
                                    multiple
                                    style={{
                                        border: "none",
                                        width: "100%",
                                        background: "none",
                                    }}
                                >
                                    <Space direction="vertical" size={2}>
                                        <Avatar
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                maxWidth: "200px",
                                            }}
                                            src="/images/product-default-img.png"
                                            alt="Store Location"
                                        />
                                        <Text
                                            style={{
                                                fontWeight: 800,
                                                fontSize: "16px",
                                                marginTop: "8px",
                                            }}
                                        >
                                            {"MiniApp Zip"}
                                        </Text>
                                    </Space>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </Col>

                    <Col xs={24} lg={16}>
                        <Row gutter={10}>
                            <Col xs={24} lg={12}>
                                
                                <Form.Item
                                    name={"miniapp_id"}
                                    label="MiniApp Name"
                                    style={{ maxWidth: "893px" }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Select {...miniappSelectProps} 
                                        options={miniappOptions}
                                        optionRender={(option) => { 
                                            return (
                                                <Space>
                                                    <span role="img" aria-label={option.data.label}>
                                                        <Avatar size={20} src={option.data.icon} /> 
                                                    </span>
                                                    {option.data.label}
                                                </Space>
                                            )
                                        }} 
                                    />
                                </Form.Item>                 
                                <Form.Item
                                    name={"version_name"}
                                    label="Version Name"
                                    style={{ maxWidth: "893px" }}
                                    rules={[
                                        { required: true, message: "please enter Version Name" },
                                        {
                                            min: 3,
                                            message: "field must be at least 3 characters",
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input placeholder="0.0.0" />
                                </Form.Item>                    
                                <Form.Item
                                    name={"version_code"}
                                    label="Version Code"
                                    style={{ maxWidth: "893px" }}
                                    rules={[
                                        { required: true, message: "please enter Version Code" },
                                        {
                                            min: 1,
                                            message: "field must be at least 1 characters",
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input placeholder="" />
                                </Form.Item>                
                                <Form.Item
                                    name={"status"}
                                    label="Status"
                                    style={{ maxWidth: "893px" }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Radio.Group>
                                        <Radio value={1}>
                                            {"Active"}
                                        </Radio>
                                        <Radio value={2}>
                                            {"Inactive"}
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>             
                                <Form.Item
                                    name={"change_log"}
                                    label="Change Log"
                                    style={{ maxWidth: "893px" }}
                                >
                                    <Input.TextArea />
                                </Form.Item> 
                                     
                                
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
}
