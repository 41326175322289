import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { useForm, Edit } from "@refinedev/antd";

import { Select, Button, Form, Input, Radio, Space } from "antd";

interface IFormValue {
    layout_name: string;
    status: number;
    note: string;
}

export const MiniDashboardLayoutEdit: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps } = useForm<IFormValue>();
    return (
        <Edit title="Edit Layout" saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    name={"layout_name"}
                    label="Layout Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter layout name" },
                        {
                            whitespace: true,
                        },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>                   
                <Form.Item
                    name={"status"}
                    label="Status"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Radio.Group>
                        <Radio value={1}>
                            {"Active"}
                        </Radio>
                        <Radio value={2}>
                            {"Inactive"}
                        </Radio>
                    </Radio.Group> 
                </Form.Item>             
                <Form.Item
                    name={"note"}
                    label="Note"
                    style={{ maxWidth: "893px" }}
                >
                    <Input.TextArea />
                </Form.Item> 
                 
            </Form>
        </Edit>
    );
}
