import React from "react";

import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
} from "@refinedev/core";

import { useForm, Create, useSelect, } from "@refinedev/antd";

import { Select, Button, Form, Input, Space, Radio } from "antd";

interface IFormValue {
    package_name: string;
    hostapp_name: string;
    status: number;
    note: string;
}

import { ILayout } from "interfaces";

export const AppstoreSDKCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { formProps, saveButtonProps } = useForm<IFormValue>();

    const { selectProps: layoutSelectProps } = useSelect<ILayout>({
        resource: "layouts",
        optionLabel: "layout_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form 
                {...formProps} 
                layout="vertical"
                initialValues={{
                    status: 1,
                    ...formProps.initialValues,
                }}
            >
                <Form.Item
                    name={"id"}
                    hidden
                >
                    <Input type="hidden" />
                </Form.Item>     
                <Form.Item
                    name={"hostapp_name"}
                    label="HostApp Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter hostapp name" },
                        {
                            whitespace: true,
                        },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>                 
                <Form.Item
                    name={"package_name"}
                    label="Package Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter package name" },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input placeholder="a.b.c" />
                </Form.Item>    

                 <Form.Item
                    name={"dashboard_layout_id"}
                    label="Layout Name"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...layoutSelectProps} />
                </Form.Item>  

                <Form.Item
                    name={"version_ios"}
                    label="Version IOS"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter package name" },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input placeholder="a.b.c" />
                </Form.Item>       
                                
                <Form.Item
                    name={"version_android"}
                    label="Version Android"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        { required: true, message: "please enter package name" },
                        {
                            min: 3,
                            message: "field must be at least 3 characters",
                        },
                    ]}
                    hasFeedback
                >
                    <Input placeholder="a.b.c" />
                </Form.Item>          

                <Form.Item
                    name={"status"}
                    label="Status"
                    style={{ maxWidth: "893px" }}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    hasFeedback
                >
                    <Radio.Group>
                        <Radio value={1}>
                            {"Active"}
                        </Radio>
                        <Radio value={2}>
                            {"Inactive"}
                        </Radio>
                    </Radio.Group>  
                </Form.Item>             
                <Form.Item
                    name={"note"}
                    label="Note"
                    style={{ maxWidth: "893px" }}
                >
                    <Input.TextArea />
                </Form.Item> 
                 
            </Form>
        </Create>
    );
}
