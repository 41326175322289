import {
    useGetIdentity,
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    useNavigation,
    HttpError,
    getDefaultFilter,
    useDelete,
    useMany,
} from "@refinedev/core";

import type { SelectProps } from 'antd';

import {
    List,
    TextField,
    useTable,
    useEditableTable,
    getDefaultSortOrder,
    DateField,
    NumberField,
    useSelect,
    SaveButton,
    useDrawerForm,
    BooleanField,
    CreateButton,
} from "@refinedev/antd";

import { 
    FormOutlined, 
    SearchOutlined, 
    MoreOutlined, 
    EditOutlined, 
    CloseCircleOutlined,
    CopyOutlined,
    GlobalOutlined, 
} from "@ant-design/icons";

import {
    Table,
    Space,
    Popover,
    Card,
    Input,
    Form,
    DatePicker,
    Select,
    Button,
    FormProps,
    Row,
    Col,
    Avatar,
    Dropdown,
    Menu,
    Grid,
    Image,
} from "antd";

import dayjs from "dayjs";

import {
    ILayout,
    IILayoutFilterVariables,
    ILayoutSection,
    ISectionType,
    IIdentity,
    ISection,
    IMiniapp,
    IMiniappSection,
} from "interfaces";

import { useMemo } from "react";

import { 
    LayoutVouchersTable as SectionVouchersTable,
    LayoutBannersTable as SectionBannersTable,
    LayoutMiniappsTable as SectionMiniappsTable,
} from "components/layout";

import { 
    EditLayoutSection,
    CreateLayoutSection, 
} from "components/sectionOf";

import { 
    EditMiniappOfSection,
    CreateMiniappOfSection, 
} from "components/miniappOfSection";

export const MiniDashboardLayoutList: React.FC<IResourceComponentsProps> = () => {
    const {
        tableProps,
        searchFormProps,
        formProps,
        isEditing,
        saveButtonProps,
        cancelButtonProps,
        setId: setEditId,
    } = useEditableTable<
        ILayout,
        HttpError,
        ILayout,
        IILayoutFilterVariables
    >({
        initialSorter: [
            {
                field: "created_at",
                order: "desc",
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = []; 
            const { q, section_id, miniapp_id } = params;

            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field: "section_id",
                    operator: "eq",
                    value: section_id,
                },
                {
                    field: "miniapp_id",
                    operator: "eq",
                    value: miniapp_id,
                },
            );
            return filters;
        },
    });

    const { data: user } = useGetIdentity<IIdentity>();

    const t = useTranslate();
    const { show, edit, list, clone } = useNavigation();

    const moreMenu = (id: number) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <EditOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    edit("layouts", id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="clone"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CopyOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    clone("layouts", id, "push", {aa:1});
                }}
            >
                {t("buttons.clone")}
            </Menu.Item> 
        </Menu>
    );

    const breakpoint = Grid.useBreakpoint();

    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Card>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col xs={24}>
                <List title={t("layouts.titles.list")}  breadcrumb={null} >
                    <Table
                        {...tableProps}
                        expandable={{
                            expandedRowRender: !breakpoint.xs
                                ? expandedRowRender
                                : undefined,
                        }}
                        rowKey="id"
                        onRow={(record) => ({
                            // eslint-disable-next-line
                            onClick: (event: any) => {
                                if (event.target.nodeName === "TD") {
                                    setEditId && setEditId(record.id);
                                }
                            },
                        })}
                    >
                        <Table.Column
                            key="layout_name"
                            dataIndex="layout_name"
                            title={t("layouts.fields.layout_name")}
                            render={(value) => <TextField value={value} />}
                            sorter
                        />            
                        <Table.Column<ILayout>
                            key="sdks"
                            dataIndex="sdks"
                            title={'SDKs'}
                            render={(_, record) => {

                                return (
                                    <Popover
                                        content={
                                            <ul>
                                                {record.sdks?.map((sdk) => (
                                                    <li key={sdk.id}>
                                                        <Button 
                                                            type="link"
                                                            target="_blank"
                                                            onClick={() => list("sdks")}
                                                        >
                                                            {sdk.hostapp_name}
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        }
                                        title="SDKs"
                                        trigger="hover"
                                    >
                                        {t("orders.fields.itemsAmount", {
                                            amount: record.sdks?.length,
                                        })}
                                    </Popover>
                                )
                            }}
                        />     
                        <Table.Column
                            key="status"
                            dataIndex="status"
                            title={t("layouts.fields.status")}
                            align="center"
                            render={(value) => (
                                <BooleanField
                                    value={value === 1}
                                    trueIcon={<TrueIcon />}
                                    falseIcon={<FalseIcon />}
                                    valueLabelTrue="Active"
                                    valueLabelFalse="Inactive"
                                />
                            )}
                            sorter
                        />  
                        <Table.Column
                            key="note"
                            dataIndex="note"
                            title={t("layouts.fields.note")}
                            render={(value) => {
                                return {
                                    children: <TextField value={value} />,
                                    props: {
                                        style: {
                                            maxWidth: "400px",
                                        },
                                    },
                                }
                            }}
                        />
                        <Table.Column
                            key="created_at"
                            dataIndex="created_at"
                            title={t("layouts.fields.created_at")}
                            render={(value) => (
                                <DateField value={value} format={t("format.date")} />
                            )}
                            sorter
                        />                         
                        <Table.Column<ILayout>
                            title={t("table.actions")}
                            dataIndex="actions"
                            key="actions"
                            align="center"
                            render={(_text, record) => {
                                return (
                                    <Dropdown
                                        overlay={moreMenu(record.id)}
                                        trigger={["click"]}
                                    >
                                        <MoreOutlined
                                            onClick={(e) => e.stopPropagation()}
                                            style={{
                                                fontSize: 24,
                                            }}
                                        />
                                    </Dropdown>
                                );
                            }}
                        /> 
                    </Table>
                </List>
            </Col>
        </Row>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    const t = useTranslate();
    
    const { selectProps: sectionSelectProps } = useSelect<ISection>({
        resource: "sections",
        optionLabel: "section_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const { queryResult, selectProps: miniappSelectProps } = useSelect<IMiniapp>({
        resource: "miniapps",
        optionLabel: "miniapp_name",
        optionValue: "id",
        onSearch: (value) => [
            {
                field: "q",
                operator: "contains",
                value,
            },
        ],
    });

    const miniappOptions: SelectProps['options'] = queryResult?.data?.data.map((item)=>{
        return {
            label: item.miniapp_name,
            value: item.id,
            icon: item.icon,
        }
    });


    return (
        <Form layout="inline" {...formProps}>
            <Form.Item label={t("search.keyword")} name="q">
                <Input
                    placeholder="Layout Name"
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
            <Form.Item label="Sections" name="section_id">
                <Select
                    {...sectionSelectProps}
                    allowClear
                    placeholder="Search Sections"
                />
            </Form.Item>
            <Form.Item label="Miniapps" name="miniapp_id">
                <Select
                    {...miniappSelectProps}
                    allowClear
                    placeholder="Search Miniapps"
                    options={miniappOptions}
                    optionRender={(option) => { 
                        return (
                            <Space>
                                <span role="img" aria-label={option.data.label}>
                                    <Avatar size={20} src={option.data.icon} /> 
                                </span>
                                {option.data.label}
                            </Space>
                        )
                    }}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t("buttons.filter")}
                </Button>
            </Form.Item>
        </Form>
    );
};

const LayoutSectionsTable: React.FC<{ record: ILayout }> = ({ record }) => {
    const t = useTranslate();

    const { mutate: mutateDelete } = useDelete();
    const { list } = useNavigation();

    const { data: typeList, isLoading } = useMany<ISectionType>({
        resource: "section-types",
        ids: [],
        queryOptions: {
            enabled: true,
        },
    });

    const {
        tableProps,
        searchFormProps,
        formProps,
        isEditing,
        saveButtonProps,
        cancelButtonProps,
        setId: setEditId,
    } = useEditableTable<ILayoutSection>({
        resource: "layout-sections",
        pagination: {
            mode: "off",
        },
        permanentFilter: [
            {
                field: "layout_id",
                operator: "eq",
                value: record.id,
            },
        ],
        syncWithLocation: false,
    });

    const {
        drawerProps: createDrawerProps,
        formProps: createFormProps,
        saveButtonProps: createSaveButtonProps,
        show: createShow,
    } = useDrawerForm<ILayoutSection>({
        action: "create",
        resource: "layout-sections",
        redirect: false,
    });

    const {
        drawerProps: editDrawerProps,
        formProps: editFormProps,
        saveButtonProps: editSaveButtonProps,
        show: editShow,
    } = useDrawerForm<ILayoutSection>({
        action: "edit",
        resource: "layout-sections",
        redirect: false,
    });

    const moreMenu = (record: ILayoutSection) => (
        <Menu
            mode="vertical"
            onClick={({ domEvent }) => domEvent.stopPropagation()}
        >
            <Menu.Item
                key="edit"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    editShow(record.id);
                }}
            >
                {t("buttons.edit")}
            </Menu.Item>
            <Menu.Item
                key="remove"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={
                    <CloseCircleOutlined
                        style={{
                            color: "#EE2A1E",
                            fontSize: 17,
                        }}
                    />
                }
                onClick={() => {
                    mutateDelete({
                        resource: "layout-sections",
                        id: record.id,
                        mutationMode: "undoable",
                    });
                }}
            >
                {"Remove"}
            </Menu.Item>
        </Menu>
    );

    const breakpoint = Grid.useBreakpoint();
    const TrueIcon = () => <span>✅</span>;
    const FalseIcon = () => <span>❌</span>;

    return (
        <List title={t("sectionOfLayout.titles.list", {
                    layout_name: record.layout_name,
                })}  
            breadcrumb={null} 
            headerButtons={({ createButtonProps }) => (
                <>
                    <CreateButton 
                        hideText={true}
                        onClick={() => createShow()}
                        meta={{ layout_id: record.id }}
                    />
                </>
            )}
        >
            <Table
                {...tableProps}
                expandable={{
                    expandedRowRender: !breakpoint.xs
                        ? expandedRowRenderSection
                        : undefined,
                }}
                rowKey="id"
                onRow={(record) => ({
                    // eslint-disable-next-line
                    onClick: (event: any) => {
                        if (event.target.nodeName === "TD") {
                            setEditId && setEditId(record.id);
                        }
                    },
                })}
            >
                <Table.Column
                    key="section_name"
                    dataIndex="section_name"
                    title={t("sectionOfLayout.fields.section_name")}
                    render={(value) => {
                        return (
                            <Button 
                                type="link"
                                target="_blank"
                                onClick={() => list("sections")}
                            >
                                {value}
                            </Button>
                        )
                    }}
                />
                <Table.Column<ISectionType>
                    key="type" 
                    dataIndex="type"
                    title={t("sectionOfLayout.fields.type")} 
                    render={(value) => {
                        if (isLoading) {
                            return <TextField value="Loading..." />;
                        }
                        //list("products")
                        return (
                            <Button 
                                type="link"
                                onClick={() => list("section-types", value)}
                            >
                                {
                                    typeList?.data.find((item) => item.id === value)
                                        ?.name
                                }
                            </Button>
                        );
                    }} 
                    sorter
                />     
                <Table.Column<ILayoutSection>
                    key="layouts"
                    dataIndex="layouts"
                    title={'Layouts'}
                    render={(_, record) => {

                        return (
                            <Popover
                                content={
                                    <ul>
                                        {record.layouts?.map((layout) => (
                                            <li key={layout.id}>
                                                <Button 
                                                    type="link"
                                                    target="_blank"
                                                    onClick={() => list("layouts")}
                                                >
                                                    {layout.layout_name}
                                                </Button>                                               
                                            </li>
                                        ))}
                                    </ul>
                                }
                                title="Layouts"
                                trigger="hover"
                            >
                                {t("orders.fields.itemsAmount", {
                                    amount: record.layouts?.length,
                                })}
                            </Popover>
                        )
                    }}
                /> 
                <Table.Column
                    key="ordering"
                    dataIndex="ordering"
                    title={t("sectionOfLayout.fields.ordering")}
                    align="center" 
                    sorter
                />
                <Table.Column
                    key="description"
                    dataIndex="description"
                    title={t("sections.fields.description")}
                />
                <Table.Column
                    key="status"
                    dataIndex="status"
                    title={t("sectionOfLayout.fields.status")}
                    align="center"
                    render={(value) => (
                        <BooleanField
                            value={value === 1}
                            trueIcon={<TrueIcon />}
                            falseIcon={<FalseIcon />}
                            valueLabelTrue="Active"
                            valueLabelFalse="Inactive"
                        />
                    )}
                /> 
                <Table.Column<ILayoutSection>
                    dataIndex="section_actions"
                    title={t("table.actions")}
                    render={(_, record) => (
                        <Dropdown
                            overlay={moreMenu(record)}
                            trigger={["click"]}
                        >
                            <MoreOutlined
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>
            <CreateLayoutSection
                drawerProps={createDrawerProps}
                formProps={createFormProps}
                saveButtonProps={createSaveButtonProps}
                layoutId={record.id}
            />
            <EditLayoutSection
                drawerProps={editDrawerProps}
                formProps={editFormProps}
                saveButtonProps={editSaveButtonProps}
            />
        </List>
    );
};

const expandedRowRender = (record: ILayout) => {
    return <LayoutSectionsTable record={record} />;
};


const expandedRowRenderSection = (record: ILayoutSection) => {
    if (record.type == 3 || record.type == 4) return <SectionBannersTable record={record} />;
    if (record.type == 5) return <SectionVouchersTable record={record} />;
    return <SectionMiniappsTable record={record} />;
};